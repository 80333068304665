import React, {useState} from "react";

// Libraries
import ReactPlayer from 'react-player/youtube';

// Hooks
import {useSelector, useDispatch} from 'react-redux';
import {updateGlobalYoutube} from "../Store/action";

const Video = ({youtubeID, image, title, imgFilter}) => {

    const videoSettings = {
        youtube: {
            playerVars: {showinfo: 0}
        }
    };

    let youtubeState = useSelector(state => state.global).youtube;

    let dispatch = useDispatch();

    const renderMarkup = (markup) => {
        return {__html: markup};
    };

    return (
        <div className="c-video">
            {youtubeID ?
                <>
                    {youtubeState ?
                        <ReactPlayer className="react-player c-video-iframe"
                                     config={videoSettings}
                                     controls={true}
                                     light={(image && image.src) ? image.src : true}
                                     playing={true}
                                     url={`https://www.youtube.com/watch?v=${youtubeID}`}
                                     poster={(image && image.src) ? image.src : ''}
                                     width='100%' height='100%'
                                     playIcon={<>{imgFilter && <div className="c-cards__item-filter"
                                                                    style={{backgroundColor: imgFilter}}/>}
                                         <button className="c-video-button" type="button">{title &&
                                         <span className="title-40">{title}</span>}<i
                                             className="icon-play"/></button>
                                     </>}/>
                        :
                        <div className="video_cookie c-video-iframe"
                             style={{backgroundImage: `url(${(image && image.src) ? image.src : ''})`}}>
                            {imgFilter && <div className="c-cards__item-filter"
                                               style={{backgroundColor: imgFilter}}/>}
                            <div>
                                <div className="tac_activate">
                                    <div className="tac_float">
                                        <strong>Youtube (Js API) {window.tarteaucitron.lang.fallback}</strong>
                                        <div className="post_text">{window.tarteaucitron.lang.video.details}</div>
                                        <div className="post_text">{window.tarteaucitron.lang.disclaimer}
                                        </div>
                                        <button className="tarteaucitronAllow" id="Eng62829edyoutubeapi"
                                                onClick={() => {
                                                    dispatch(updateGlobalYoutube(true));
                                                    window.tarteaucitron.cookie.create('youtubeapi', true);
                                                    window.tarteaucitron.userInterface.color('youtubeapi', true);
                                                    window.tarteaucitron.state.youtubeapi = true;
                                                }}>✓ {window.tarteaucitron.lang.allow}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </> :
                <>
                    {image && <img src={image.src} alt={image.alt} className="c-video-iframe"/>}
                    <button className="c-video-button" type="button">{title &&
                    <span className="title-40">{title}</span>}<i
                        className="icon-play"/></button>
                </>

            }
        </div>
    )
};

export default Video;