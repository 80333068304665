import React, {Component} from 'react';
import "./elevator-content.scss"
import ElevatorGraph from './elevator-graph';
import ReactDOM from 'react-dom';

// External lib
// import ReactGA from 'react-ga';

class ElevatorContent extends Component {

    state = {
        currentIndex: null,
        name: "",
        infos: {},
        sound: null,
        finished: false,
        currentButtonStatus: true,
        soundStatus: true,
        cutSound: false
    };
    audio = null;

    onSubmit = this.props.onSubmit;


    componentDidMount() {
        //this.ga = this.props.ga;
        this.dom = ReactDOM.findDOMNode(this);
        this.inputName = this.dom.querySelectorAll(".elevator__formName input");

        this.mounted = true;
        this.data.steps.forEach((item, index) => item.trueIndex = index);
        setTimeout(() => this.goNext(), 100);
    }

    componentWillUnmount() {
        this.soundStatus = false;
        this.mounted = false;
    }

    render() {
        this.data = this.props.data;

        if (!this.data || !this.data.steps || this.data.steps.length === 0) {
            return null;
        }
        
        return (
            <div className="elevator__box" data-custom-color={this.props.haveCustomPrimaryColor}>
                <ElevatorGraph sound={this.state.soundStatus} nextSound={this.state.sound}
                               onSoundEnded={() => this.interfaceNext()} cutSound={this.state.cutSound}/>
                {
                    this.data.steps.map((item, i) => {

                        let message = item.message;
                        if (this.state.name !== "") {
                            message = message.replace("[NAME]", "<span class='elevator__name'>" + this.state.name + "</span>")
                        }

                        let needFormInput = (item.type === "form_name");
                        let needOptions = (item.type === "form_options" && item.options && item.options.length > 0);

                        return (
                            <React.Fragment key={item.id}>
                                <div
                                    onTransitionEnd={i === this.state.currentIndex ? this.transitionEnd.bind(this) : null}
                                    className={"elevator__etape elevator__etape--" + (i > this.state.currentIndex || this.state.currentIndex === null ? "next" : i < this.state.currentIndex ? "prev" : "current")}>
                                    <div className="elevator__etapeBox">
                                        <p className={"elevator__texte" + (item.style ? " elevator__texte--" + item.style : "")}
                                           dangerouslySetInnerHTML={{__html: message}}></p>
                                        {
                                            needOptions ? item.options.map((opt, j) => {
                                                return (
                                                    <div key={item.name + '_' + opt.id}
                                                         className={"elevator__option " + (j === 0 ? "elevator__option--first" : (j === item.options.length - 1 ? "elevator__option--last" : ""))}>
                                                        <button className={"elevator__texte "}
                                                                disabled={(i !== this.state.currentIndex)}
                                                                data-id={opt.id}
                                                                data-name={item.name} data-next={opt.next}
                                                                data-response={opt.titre}
                                                                data-question={item.name}
                                                                onClick={e => this.selectOption(e)}>{opt.titre}<span>{opt.titre}</span>
                                                        </button>
                                                    </div>
                                                )
                                            }) : null
                                        }
                                        {
                                            needFormInput ? (
                                                <div className="elevator__formName"><input
                                                    disabled={(i !== this.state.currentIndex)} type="text"
                                                    data-needfocus={i === this.state.currentIndex}
                                                    onKeyPress={e => this.submitName(e)}></input>
                                                    <button className="elevator__texte activated"
                                                            onClick={e => this.validateName(e)}>{this.data.message_valider}</button>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>


                                {/* Bonjour,
                                Bienvenue
                                Ici*/}
                                {i < 3 &&
                                <button
                                    className={"c-button c-button--" + (i > this.state.currentIndex || this.state.currentIndex === null ? "next" : i < this.state.currentIndex ? "prev" : "current") + " c-button--" + (this.state.currentButtonStatus ? 'active' : 'disabled')}
                                    onClick={() => {
                                        this.goNext(4);
                                        /*if (this.ga) {
                                            ReactGA.event({
                                                category: 'elevator_skip_intro',
                                                action: 'Click',
                                                label: this.data.message_intro
                                            });
                                        }*/
                                    }}>{this.data.message_intro}</button>
                                }

                                {/* Questions */}
                                {(i > 3 && !this.data.steps[i].last) &&
                                <button
                                    className={"c-button c-button--" + (i > this.state.currentIndex || this.state.currentIndex === null ? "next" : i < this.state.currentIndex ? "prev" : "current") + " c-button--" + (this.state.currentButtonStatus ? 'active' : 'disabled')}
                                    onClick={() => {
                                        for (let x = 1; x < 100; x++) {
                                            if (this.data.steps[parseInt(i + x)] && this.data.steps[parseInt(i + x)].stop) {
                                                //  console.log('goTo ' + parseInt(i + x + 1));
                                                this.goNext(parseInt(i + x + 1));
                                                /*if (this.ga) {
                                                    ReactGA.event({
                                                        category: 'elevator_skip_' + this.data.steps[parseInt(i + x)].name,
                                                        action: 'Click',
                                                        label: this.data.message_next
                                                    });
                                                }*/
                                                return;
                                            }
                                        }

                                    }}>{this.data.message_next}</button>
                                }
                            </React.Fragment>
                        )
                    })
                }
                <div className="elevator__sound">
                    <button className={`${!this.state.soundStatus ? 'sound-disabled' : ''}`}
                            onClick={() => this.setState({soundStatus: !this.state.soundStatus})}><i
                        className="icon-sound-off"></i>
                    </button>
                </div>
                <div className="elevator__regle">
                    <div className="elevator__regle__titre">Matching</div>
                    <div className="elevator__regle__curseur"
                         style={{height: (this.state.finished ? "100" : 100 * (this.state.currentIndex + 1) / (this.data.steps.length)) + "%"}}></div>
                </div>
            </div>
        );
    }

    transitionEnd(e) {
        let isAnyFocus = false;
        this.inputName.forEach(input => {
            if (!isAnyFocus && input.dataset.needfocus === "true") {
                //   console.log("test");
                isAnyFocus = true;
                input.focus();
            }
        })
    }

    submitName(e) {
        if (e.which === 13) {
            this.validateName(e);
        }
    }

    validateName(e) {
        let name = e.target.parentElement.querySelector("input").value;
        let infos = this.state.infos;
        infos["firstname"] = name;
        this.setState({infos, name});
        this.nameDone = true;
        this.interfaceName();
    }

    selectOption(e) {
        if (!this.state.infos[e.target.dataset.name]) {
            let infos = this.state.infos;
            // infos[e.target.dataset.name] = e.target.dataset.id;
            infos[e.target.dataset.name] = e.target.dataset.response;
            this.setState({infos, currentButtonStatus: false});
            e.target.classList.add("selected");
            this.next = parseInt(e.target.dataset.next);
            setTimeout(() => {
                this.formClicked = true;
                this.interfaceForm();
            }, 1000);
           /* if (this.ga) {
                ReactGA.event({
                    category: 'elevator_' + e.target.dataset.question,
                    action: 'Click',
                    label: e.target.dataset.response
                });
            }*/
        }
    }

    interfaceNext() {
        if (this.mounted) {
            this.setState({cutSound: false});
            if (this.data.steps[this.state.currentIndex].type === "default") {
                this.goNext();
            } else if (this.data.steps[this.state.currentIndex].type === "form_options") {
                this.soundFinish = true;
                this.interfaceForm();
            } else if (this.data.steps[this.state.currentIndex].type === "form_name") {
                this.soundFinish = true;
                this.interfaceName();
            }
        }
    }

    interfaceForm() {
        // if (this.formClicked) {
        if (this.soundFinish && this.formClicked) {
            this.soundFinish = false;
            this.formClicked = false;
            this.goNext(this.next);
            this.next = null;

        }
    }

    interfaceName() {
        if (this.soundFinish && this.nameDone) {
            // if (this.nameDone) {
            this.soundFinish = false;
            this.nameDone = false;
            this.goNext();
        }
    }

    goNext(nextForced) {
        if (this.data.steps) {
            const nextItem = this.getNextItem(nextForced);
            if (nextItem) {
                if (nextForced) {
                    setTimeout(() => {
                        this.setState({
                            currentItem: nextItem,
                            currentIndex: nextItem.trueIndex,
                            sound: nextItem.audio,
                            currentButtonStatus: true,
                            cutSound: true
                        });
                    }, 1000);
                } else {
                    this.setState({
                        currentItem: nextItem,
                        currentIndex: nextItem.trueIndex,
                        sound: nextItem.audio,
                        currentButtonStatus: true
                    });
                }
            }
            else {
                this.setState({finished: true});
                this.onSubmit(this.state.infos);
                /*if (this.ga) {
                    ReactGA.event({
                        category: 'elevator_end',
                        action: 'Click',
                        label: 'Elevator terminé'
                    });
                }*/
            }
        }
    }

    getNextItem(nextForced) {
        if (nextForced) {
            return this.data.steps.filter(item => item.id === nextForced)[0];
        } else if (this.state.currentItem) {
            return this.data.steps.filter(item => item.id === this.state.currentItem.next)[0];
        } else {
            return this.data.steps[0];
        }
    }
}

export default ElevatorContent;