import React, {useState, useEffect} from "react";

// Hooks
import {useDispatch} from 'react-redux';
import {updateGlobalStorePage, updateGlobalStorePageBis} from '../Store/action';
import useApi from '../Hooks/useApi';
import useSearchApi from '../Hooks/useSearchApi';

// Components
import {LoaderPage, LoaderResults} from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import MainHeader from "../Layout/main/header";
import SearchOffers from "../Components/search-offers";
import SearchItem from "../Components/search-item";

const TemplateSearch = ({_uid}) => {

    const [dataFetch, isLoaded] = useApi({name: 'pages', _uid: _uid});

    // Get Search API results
    const [value, setValue] = useState('');
    const [result, resultIsLoaded] = useSearchApi({value});
    const [end, setEnd] = useState(10);
    const [total, setTotal] = useState('');

    let searchTerm = window.location.href.split('?s=');

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-search-list'));
        dispatch(updateGlobalStorePageBis(''));
        setValue(decodeURIComponent(searchTerm[1]));
    }, [dispatch, isLoaded]); // eslint-disable-line

    // Event when input search is updated
    const handleChange = (e) => {
        setEnd(10);
        setValue(e);
    };

    const handleLoadMore = () => setEnd(end + 10);

    useEffect(() => {
        if (resultIsLoaded) {
            let total = result.data.total;
            let pluriel = '';
            total > 0 ? pluriel = 's' : pluriel = '';
            let title = value + ' : <data style="color: black">' + total + ' résultat' + pluriel + '</data>';
            setTotal(title);
        }
    }, [resultIsLoaded]); // eslint-disable-line

    function renderPage() {

        const {metas, content: {main: {btn_see_more /*, no_results*/, btn_search, placeholder_search}}} = dataFetch;

        return (
            <>
                <Metatags {...metas} />

                <main className="main">

                    <MainHeader title={total} color="purple"/>

                    <div className="main__content" data-color="purple">
                        <SearchOffers searchPage={true} placeholder_search={placeholder_search} btn_search={btn_search}
                                      value={value} handleChange={handleChange}/>

                        <div className="container container--mini">
                            {resultIsLoaded ?
                                (result && result.data.total > 0) &&
                                <div className="search__results">
                                    {result.data.items.slice(0, end).map((item, index) => <SearchItem
                                        key={`search-item-${index}`} {...item} />)}
                                </div>
                                :
                                <LoaderResults/>
                            }
                        </div>

                        {resultIsLoaded && result && result.data.total > end &&
                        <div className="c-search-button">
                            <button type="button" className="c-button js-loadmore"
                                    onClick={handleLoadMore}>{btn_see_more}</button>
                        </div>
                        }
                    </div>
                </main>
            </>
        )
    }

    return isLoaded ? renderPage() : <LoaderPage/>
};


export default TemplateSearch;