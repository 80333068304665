import React from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import Ambassador from '../Components/ambassador';
import CLink from '../Components/link';

const FHomeAmbassadors = ({ ambassadors }) => {

    const { title, logo, list, link } = ambassadors;

    let { m1024 } = useSelector(state => state.breakpoint);

    return (
        <>
            {(list && list.length > 0) &&
                <div className="home-ambassadors paddingcontrol">
                    <div className="container">
                        <div className="title-50">
                            {title && <span>{title}</span>}
                            {(logo && Object.keys(logo).length > 0) &&
                                <img src={logo.src} alt={logo.alt} />
                            }
                        </div>

                        <>
                            {m1024 ?
                                <div className="home-ambassadors-sliderCtn">

                                    <Swiper className="home-ambassadors-slider"
                                        spaceBetween={20}
                                        speed={100}
                                        autoHeight={true}
                                        pagination={{ clickable: true }}
                                        slidesPerView={1}
                                        grabCursor={true}
                                        noSwiping={false}
                                        breakpoints={{
                                            768: {
                                                spaceBetween: 22,
                                                slidesPerView: 2,
                                            }
                                        }}
                                    >
                                        {
                                            list.map((item, key) => {
                                                return (
                                                    <SwiperSlide key={key}>
                                                        <Ambassador testimony={item} />
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                </div>
                                :
                                <div className="home-ambassadorsFlex">
                                    {
                                        list.map((item, key) => {
                                            return (
                                                <Ambassador testimony={item} key={key} />
                                            )
                                        })
                                    }
                                </div>
                            }
                        </>

                        {(link && Object.keys(link).length > 0) &&
                            <div className="home-ambassadors-button">
                                {link.title &&
                                    <CLink {...link} className={`c-button`} />
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default FHomeAmbassadors;