import React, { memo, Fragment } from 'react'
import PropTypes from 'prop-types';

const Blogposts = memo(({ blogposts }) => (
    <div className="c-blog">
        <div className="c-blog--title">
            {blogposts.title}
        </div>
        <hr className="c-blog--separator" />
        <ul className="c-blog--post">
            {blogposts.items.map((post) => (
                <Fragment key={post.title}>
                    <li className="c-blog--post--list">
                        <a className="c-blog--post--list--link" href={post.url} target="_blank">
                            {post.title}
                        </a>
                    </li>
                    <hr className="c-blog--post--separator" />
                </Fragment>
            ))}
        </ul>
        <a className="c-blog--button" target={blogposts.cta.target} href={blogposts.cta.url}>
            {blogposts.cta.title}
        </a>
    </div>
));

export default Blogposts;

Blogposts.propTypes = {
    blogposts: PropTypes.shape({
        title: PropTypes.string.isRequired,
        posts: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
        }))
    }).isRequired
}