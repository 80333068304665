import React from 'react';

const FTextImg = ({index, position, text, image}) => {

    const renderMarkup = (markup) => {
        return {__html: markup};
    };

    return (
        <div className={`flexible f-text${position === 'right' ? ' f-text--imgRight' : '' } margincontrol`}
             data-bg={(index % 2 === 0) ? 'grey' : 'white'}>
            <div className="container container--medium">
                <div className="f-textFlex">
                    <div className="f-text-img">
                        <img src={image.src} alt={image.alt}/>
                    </div>
                    <div className="f-text-ctn" dangerouslySetInnerHTML={renderMarkup(text)}></div>
                </div>
            </div>
        </div>
    )
};

export default FTextImg;