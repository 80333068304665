import React from "react";

// Components
import Share from "../../Components/share";
import CLink from "../../Components/link";

const MainHeader = ({back, color, visuel, title, introduction, sharer, customPrimaryColor}) => {
    const haveCustomPrimaryColor = !!customPrimaryColor;
    const customColorStyles = haveCustomPrimaryColor ? { color: customPrimaryColor } : {};

    const renderMarkup = (markup) => {
        return {__html: markup};
    };

    return (
        <div className="main__header" data-color={color}>
            {visuel &&
                <div className="main__header-media">
                    <img src={visuel.src} alt={visuel.alt}/>
                </div>
            }
            <div className="main__header-intro">
                <div className="container">
                    {sharer ?
                        (
                            <div className="main__header-introFlex">
                                {(sharer && Object.keys(sharer).length > 0) &&
                                <div className="main__header-introFlexShare">
                                    <Share {...sharer}/>
                                </div>
                                }
                                <div className="main__header-introFlexText">
                                    {(back && Object.keys(back)) &&
                                    <CLink {...back} className={`c-link c-link--back`}>
                                        <i className="icon-arrow-right"></i>
                                        <span dangerouslySetInnerHTML={renderMarkup(back.title)}></span>
                                    </CLink>
                                    }
                                    <h1 className="title-50" dangerouslySetInnerHTML={renderMarkup(title)} style={customColorStyles}></h1>
                                    {introduction &&
                                    <div className="paragraph"
                                         dangerouslySetInnerHTML={renderMarkup(introduction)}></div>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="main__header-introFlexText">
                                {(back && Object.keys(back)) &&
                                <CLink {...back} className={`c-link c-link--back`}>
                                    <i className="icon-arrow-right"></i>
                                    <span dangerouslySetInnerHTML={renderMarkup(back.title)}></span>
                                </CLink>
                                }
                                <h1 className="title-50" dangerouslySetInnerHTML={renderMarkup(title)} style={customColorStyles}></h1>
                                {introduction &&
                                <div className="paragraph"
                                     dangerouslySetInnerHTML={renderMarkup(introduction)}></div>
                                }
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
};

export default MainHeader;