import {useEffect} from 'react';
import {useSelector} from "react-redux";
import { element } from 'prop-types';

function isScriptAlreadyPresent(url) {
    let scripts = Array.prototype.slice.call(document.scripts);
    return scripts.some(function (el) {
        return el.src && el.src !== undefined && el.src === url;
    });
}

function removeCaaskCustom() {
    ['.btn-popin-caask', '.btn-popin-caask-mobile', '.popin-caask'].forEach(elem => document.querySelectorAll(elem).forEach(item => item.parentNode.removeChild(item)))
}


const useScript = (url, elementId) => {
    const lang = useSelector(state => state.global).lang;
    useEffect(() => {
        if ((window.location.href.indexOf(process.env.REACT_APP_DOMAIN_1) > -1 || window.location.hostname === "localhost") && lang === 'fr') {
            if (!isScriptAlreadyPresent(url)) {
                const script = document.createElement('script');
                script.id = elementId;
                script.src = url;
                script.async = true;
                document.body.appendChild(script);
            }
            
            return () => {
                const element = document.getElementById(elementId);
                if (element != null) {
                    removeCaaskCustom();
                    window.removeCaask();
                    window.clearCaask();
                    document.body.removeChild(element);
                }
            }
        }
    }, [url, elementId]);
};

export default useScript;