import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

const Menu = ({items, closeMenu, haveCustomHeader, primaryColor}) => {
    return (
        <ul className="header__menu">
            {items && items.map((item, key) =>
                <MenuItem
                    key={key}
                    {...item}
                    closeMenu={closeMenu}
                    haveCustomHeader={haveCustomHeader}
                    primaryColor={primaryColor}
                />
            )}
        </ul>
    )
};

export default Menu;

const MenuItem = ({title, link, submenu, closeMenu, haveCustomHeader, primaryColor}) => {
    let {m1199} = useSelector(state => state.breakpoint);
    const [submenuOpen, setSubmenuOpen] = useState(false);

    return (
        <li className={`${submenu.length > 0 ? 'has-children' : ''}`} data-focus={submenuOpen} onFocus={() => {
            setSubmenuOpen(true)
        }} onBlur={() => {
            setSubmenuOpen(false)
        }}
        data-custom-header={haveCustomHeader}>

            {(link.url && !m1199) ? (
                <>
                    {link.internal ?
                        <NavLink to={link.url} title={link.title} target={link.target}
                                 onClick={closeMenu} dangerouslySetInnerHTML={{__html: title}}></NavLink>
                        :
                        <a href={link.url} title={link.title} target={link.target}
                           onClick={closeMenu} dangerouslySetInnerHTML={{__html: title}}></a>
                    }
                </>
            ) : (
                <span onClick={() => setSubmenuOpen(!submenuOpen)} dangerouslySetInnerHTML={{__html: title}}></span>
            )}

            {submenu.length > 0 && (
                <SubMenu submenu={submenu} parentItem={title} closeMenu={closeMenu} submenuOpen={submenuOpen}
                    clickParent={() => setSubmenuOpen(!submenuOpen)} 
                    clickItem={() => {
                        closeMenu();
                        setSubmenuOpen(!submenuOpen);
                    }}
                    haveCustomHeader={haveCustomHeader}
                    primaryColor={primaryColor}
                />
            )}
        </li>
    )
};

const SubMenu = ({submenu, parentItem, closeMenu, submenuOpen, clickParent, clickItem, haveCustomHeader, primaryColor}) => {
    const subMenuStyles = primaryColor ? {
        style: {
            borderColor: primaryColor
        }
    } : null;
    const customColorStyle = primaryColor ? { color: primaryColor } : {};

    return (
        <div className={`menu__submenu ${submenuOpen ? 'menu__submenu--opened' : ''}`} {...(subMenuStyles || {})}>
            <div className="menu__submenuInner">
                <div className="menu__submenu-parent" onClick={clickParent}>
                    <i className="icon-arrow-left"></i>
                    {parentItem}
                </div>
                <div className="menu__submenu-container">
                    <ul className={submenu.length < 3 ? `maxWidth` : ''}>
                        {submenu && submenu.map(({title, link, submenu}, key) =>
                            <li key={key} className={submenu.length > 7 ? `column` : ''}>
                                {submenu.length > 0 ? (
                                    <>
                                        <span dangerouslySetInnerHTML={{__html: title}} style={customColorStyle}></span>
                                        <SubMenuSubMenu submenu={submenu} closeMenu={closeMenu} clickItem={clickItem} haveCustomHeader={haveCustomHeader} primaryColor={primaryColor} />
                                    </>
                                ) : (
                                    <>
                                        {link.internal ?
                                            <NavLink to={link.url} title={link.title} target={link.target}
                                                     onClick={clickItem}
                                                     dangerouslySetInnerHTML={{__html: title}} style={customColorStyle}></NavLink>
                                            :
                                            <a href={link.url} title={link.title} target={link.target}
                                               onClick={clickItem} dangerouslySetInnerHTML={{__html: title}} style={customColorStyle}></a>
                                        }
                                    </>
                                )}

                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const SubMenuSubMenu = ({submenu, closeMenu, clickItem, haveCustomHeader, primaryColor}) => {
    const customColorStyle = haveCustomHeader && primaryColor ? { color: primaryColor } : {};

    return (
        <ul>
            {submenu && submenu.map(({title, link}, key) =>
                <li key={key} data-custom-header={haveCustomHeader} style={customColorStyle}>
                    {link.internal ?
                        <NavLink to={link.url} title={link.title} target={link.target}
                                 onClick={clickItem} dangerouslySetInnerHTML={{__html: title}} data-custom-header={haveCustomHeader}></NavLink>
                        :
                        <a href={link.url} title={link.title} target={link.target}
                           onClick={clickItem} dangerouslySetInnerHTML={{__html: title}} data-custom-header={haveCustomHeader}></a>
                    }
                </li>
            )}
        </ul>
    );
};