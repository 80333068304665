import React, { useState, useEffect } from "react";

// Library
import Select from 'react-select';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { updateGlobalStorePage, updateGlobalStorePageBis } from '../Store/action';
import useApi from '../Hooks/useApi';

// Components
import { LoaderPage } from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import MainHeader from "../Layout/main/header";
import Job from "../Components/job";
import SearchOffers from "../Components/search-offers";
import TestimonyList from "../Components/testimony-list";

const TemplateJobSectors = ({ _uid }) => {

    const lang = useSelector(state => state.global).lang;
    const userID = useSelector(state => state.global).userId;

    const [dataFetch, isLoaded] = useApi({ name: 'pages', _uid: _uid });

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-job-sectors-list'));
        dispatch(updateGlobalStorePageBis(''));
    }, [dispatch]); // isLoaded


    // Save params for query
    const [value, setValue] = useState('');
    const [searchPath, setSearchPath] = useState('');


    const [params, setParams] = useState({
        'lang': lang,
        'keyword': value,
        'tax_sector': '',
        'tax_contract': '',
        'tax_place': '',
        'tax_experience': '',
        'tax_degree': '',
        'tax_brands': '',
        'tax_department': '',
        'tax_job': '',
        'tax_city': '',
        'tax_country': '',
        'tax_channel': '',
        'jobcode': '',
        'tax_community_job': '',
        'external': false,
        'userID': userID !== null ? userID : '',
        "from": 0,
        'size': 9
    });

    // Event when input search is updated
    const handleChange = (e) => {
        setValue(e);
        setParams({
            ...params,
            keyword: e
        });
    };

    const handleFilters = (e, id) => {
        let values = [];
        e && e.map(({ value }) => values.push(value));
        setParams({
            ...params,
            [id]: values.join()
        })
    };

    useEffect(() => {
        let index = 0;
        let path = '';
        for (const filter in params) { // loop sur les params
            if (params[filter] !== '') {// je ne prends que les paramètres remplis sauf lang et userID
                if (index === 0) {
                    path = `?${filter}=${params[filter]}`;
                } else {
                    path += `&${filter}=${params[filter]}`
                }
                index++;
            }
        }
        setSearchPath(path);
    }, [params]);

    function renderPage() {
        const { metas, content: { top: { title, introduction }, main: { list }, bottom: { discover_jobs, testimonies, elevator } } } = dataFetch;


        return (
            <>
                <Metatags {...metas} />

                <main className="main">

                    <MainHeader title={title} introduction={introduction} />

                    <div className="main__content">
                        <div className="container">
                            <div className="main__contentFlex job-filter">
                                <h2 className="title-40">{list.title}</h2>

                                {(list.filters && Object.keys(list.filters).length > 0) &&
                                    <SelectItem {...list.filters} />
                                }
                            </div>

                            <JobSectorsList items={list.items} />
                        </div>
                    </div>

                    {(discover_jobs && Object.keys(discover_jobs).length > 0) &&
                        <SearchOffers {...discover_jobs} handleChange={handleChange} handleFilters={handleFilters}
                            searchPath={searchPath} />
                    }

                    {(testimonies && testimonies.items && testimonies.items.length > 0) &&
                        <>
                            {(elevator && Object.keys(elevator).length > 0) ?
                                (
                                    <TestimonyList {...testimonies} elevator={elevator} />
                                ) : (
                                    <TestimonyList {...testimonies} />
                                )
                            }
                        </>
                    }

                </main>
            </>
        )
    }

    return isLoaded ? renderPage() : <LoaderPage />
};

const JobSectorsList = ({ items }) => {
    return (
        <>
            {items &&
                <div className="main__contentFlex job-list">
                    {
                        items.map((item, key) => <Job item={item} key={key} />)
                    }
                </div>
            }
        </>
    )
};

const SelectItem = ({ title, id, choices }) => {
    const history = useHistory();

    let choicesSelect = [];
    choices.map(({ link }) => choicesSelect.push({ label: link.title, value: link.url }));

    return (
        <div className="c-form-selectCtn">
            <label htmlFor={id} className="hidden">{title}</label>
            <Select options={choicesSelect} placeholder={title} className="c-form-select" id={id}
                classNamePrefix="c-form-select" onChange={event => history.push(event.value)} />
        </div>
    )
};

export default TemplateJobSectors;