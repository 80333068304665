import React from "react";
import {Link} from "react-router-dom";

const Job = ({item: {image, title, excerpt, link}}) => {
    return (
        <>
            {link.internal ?
                <Link to={link.url} className="c-job" title={link.title}>
                    <div className="c-job-img">
                        {image.src &&
                        <img src={image.src} alt={image.alt}/>
                        }
                    </div>
                    <div className="c-job-content">
                        <h3 className="title-30">{title}</h3>
                        <p>{excerpt}</p>
                    </div>
                </Link>
                :
                <a href={link.url} className="c-job" title={link.title}>
                    <div className="c-job-img">
                        {image.src &&
                        <img src={image.src} alt={image.alt}/>
                        }
                    </div>
                    <div className="c-job-content">
                        <h3 className="title-30">{title}</h3>
                        <p>{excerpt}</p>
                    </div>
                </a>
            }
        </>
    )
};

export default Job;