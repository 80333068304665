import React from "react";
import CLink from "./link";
import {Link} from "react-router-dom";

const Keynumbers = ({image, title, numbers, text, link}) => {
    return (
        <div className="c-keynumbers">
            <div className="container">
                <h3 className="title-50 c-keynumbers-title">
                    <img src={image.src} alt={image.alt}/>
                    {title}
                </h3>
                {(numbers && numbers.length > 0) &&
                <ul className="c-keynumbers-numbers">
                    {
                        numbers.map(({number, legend}, key) => {
                            return (
                                <li key={key}><span>{number}</span>{legend}</li>
                            )
                        })
                    }
                </ul>
                }
                {text && <p>{text}</p>}
                {(link && Object.keys(link).length > 0) &&
                // exemple link.param = natixis
                <div className="c-keynumbers-button">
                    {link.url && link.param ?
                        <Link to={`${link.url}?tax_brands=${link.param}`} className={`c-button`}>
                            {link.title}
                        </Link>
                    :
                        <CLink {...link} className={`c-button`}/>
                    }
                </div>
                }
            </div>
        </div>
    )
};

export default Keynumbers;