import React from "react";
import {Link} from "react-router-dom";

// Components
import Video from "./video";
import Quote from "./quote";

const Testimony = ({image, quote, author, role, link, tags}) => {

    return (
        <>
            {link.internal ?
                <Link to={link.url} title={link.title} className="c-testimony grid-item-content"
                      data-sector={tags ? tags.tax_sector : ''} data-brand={tags ? tags.tax_brands : ''}>
                    {image.src &&
                    <Video image={image}/>
                    }
                    <Quote text={quote} name={author} role={role}/>
                </Link>
                :
                <a href={link.url} title={link.title} className="c-testimony grid-item-content"
                   data-sector={tags ? tags.tax_sector : ''} data-brand={tags ? tags.tax_brands : ''}>
                    {image.src &&
                    <Video image={image}/>
                    }
                    <Quote text={quote} name={author} role={role}/>
                </a>
            }

        </>
    )
};

export default Testimony;