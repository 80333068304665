import React, {useState, useEffect} from "react";

// Hooks
import {useDispatch, useSelector} from 'react-redux';
import {updateGlobalStorePage, updateGlobalStorePageBis} from '../Store/action';
import useApi from '../Hooks/useApi';

// Components
import {LoaderPage} from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import MainHeader from "../Layout/main/header";
import AlertPush from "../Components/alert-push";

const TemplateUnsubscribeAlert = ({_uid}) => {

        let page = useSelector(state => state.page).page;
        //const [dataFetch, isLoaded] = useApi({name: 'pages', _uid: _uid});
        const [unsubscribeDatas, setUnsubscribeDatas] = useState({});
        const [dataFetch, isLoaded] = useApi(unsubscribeDatas);


        let dispatch = useDispatch();
        useEffect(() => {
            dispatch(updateGlobalStorePage('t-unsubscribe'));
            dispatch(updateGlobalStorePageBis(''));
        }, [dispatch]); // isLoaded

        // PARAM H IN URL
        useEffect(() => {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const h = params.get('h');
            if (h) {
                setUnsubscribeDatas({name: 'jobs/delete/alert', _uid: _uid, params: '&h=' + h});
            }
        }, [page]); // eslint-disable-line

        function renderPage() {
            const {
                metas,
                content: {
                    top: {title, back},
                    main: {chapo, job_alert}
                }
            } = dataFetch;


            return (
                <>
                    <Metatags {...metas} />
                    <main className="main">
                        <MainHeader title={title} introduction={chapo} back={back}/>


                        <div className="container">
                            <div className="c-buttonCtn">
                                {(job_alert && Object.keys(job_alert).length > 0) &&
                                <AlertPush {...job_alert} buttonOnly={true}/>
                                }
                            </div>
                        </div>


                    </main>
                </>
            )
        }

        return isLoaded ? renderPage() : <LoaderPage/>
    }
;

export default TemplateUnsubscribeAlert;