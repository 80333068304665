const initialState = {
    page: 't-home',
    path_fr: null,
    path_en:null,
};

/**
 * PAGE NAME REDUCER
 * @param {*} state
 * @param {*} action
 */
export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_PAGE':
            return {...state, page: action.value};
        case 'UPDATE_PAGEBIS':
            return {...state, pagebis: action.value};
        case 'UPDATE_PATH_FR':
            return {...state, path_fr: action.value};
        case 'UPDATE_PATH_EN':
            return {...state, path_en: action.value};
        default:
            return state
    }
}