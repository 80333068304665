import React, { useEffect, useState } from "react";

// Hooks
import useApi from "../../Hooks/useApi";
import { useSelector, useDispatch } from 'react-redux';
import { updateGlobalUser, updateModalsOpen } from "../../Store/action";

// Libraries
import Axios from "axios";
import { useForm } from "react-hook-form";
import LinkedinLoginButton from 'react-linkedin-login-popup';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

// Components
import Modal from "./modal";
import CLink from "../link";

const LoginModal = ({customColors}) => {
    let dispatch = useDispatch();

    // multisite : get current site
    let site = useSelector(state => state.global).site;

    // ID Login with social network
    const host = window.location.origin + '/auth/linkedin/callback';

    // Prod APP
    const linkedinClientID = '78rbgraakog4qe';
    const linkedinClientSecret = 'QxKjPT5lJbXvyQmX';
    // Dev test APP
    // const linkedinClientID = '77lvlj8rz6m39b';
    // const linkedinClientSecret = 'pKiLclg3ZBJUeA8m';

    const googleClientID = '753326725316-bdp580nafg0b0bvkevj0glgfpqsf79m7.apps.googleusercontent.com';
    // const facebookClientID = '651067112256537';
    const facebookClientID = '1791072304401632';

    // Modals workflow
    const useSelectorModals = useSelector(state => state.modals);
    const modalsOpen = useSelectorModals.openModal;
    const type = useSelectorModals.loginType;
    //const redirect = useSelectorModals.redirectButton;
    const openingId = useSelectorModals.openingId;


    const handleRegister = () => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'login': false, 'register': true }));
    };
    const handleAlert = () => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'login': false, 'alert': true }));
    };
    const handleCV = () => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'login': false, 'cv': true }));
    };
    const handleForgotPassword = () => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'login': false, 'forgotPassword': true }));
    };



    // Login modal
    // API
    const [dataFetch, isLoaded] = useApi({ name: 'user/popin/login' });


    // User Id
    const user = useSelector(state => state.global).userId;
    let [userId, setUserId] = useState(user);
    useEffect(() => {
        dispatch(updateGlobalUser(userId));
    }, [dispatch, userId]);

    function setCookie(name, value, days) {
        let d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    // Login form
    const { register, handleSubmit, errors } = useForm();

    let [successSend, setSuccessSend] = useState(false);
    let [globalError, setGlobalError] = useState(null);

    const onSubmit = (data) => {
        setGlobalError(null);

        let formData = new FormData();

        formData.append('username', data.loginEmail);
        formData.append('password', data.loginPassword);

        let fetch = Axios.post(`${site}/wp-json/bpce/v1/user/login/`, formData);


        fetch.then(res => {
            let { status, data } = res;

            if (data.success && status === 200 && !Object.keys(errors).length) {
                handleSuccess(data.user_id);
            } else if (!data.success) {
                handleFailure(data.message);
            }
        })
    };


    const socialResponseFacebook = (response) => {
        if (response) {
            let { id, email, first_name, last_name, accessToken } = response;
            let formData = new FormData();
            if (type === 'postulate') {
                email && formData.append('mail', email);
                first_name && formData.append('firstname', first_name);
                last_name && formData.append('lastname', last_name);
                openingId && formData.append('openingid', openingId);
                if (openingId) {
                    createATSUser(formData);
                }
            } else {
                id && formData.append('id', id);
                email && formData.append('mail', email);
                first_name && formData.append('firstname', first_name);
                last_name && formData.append('lastname', last_name);
                accessToken && formData.append('token', accessToken);
                formData.append('type', 'facebook');
                socialConvert(formData);
            }
        }
    };

    const socialResponseGoogle = (response) => {
        if (response) {
            if (response.error) {
                handleFailure('Google : ' + response.details);
            } else {
                let { profileObj: { googleId, email, givenName, familyName }, tokenId } = response;
                let formData = new FormData();
                if (type === 'postulate') {
                    email && formData.append('mail', email);
                    givenName && formData.append('firstname', givenName);
                    familyName && formData.append('lastname', familyName);
                    openingId && formData.append('openingid', openingId);
                    if (openingId) {
                        createATSUser(formData);
                    }
                } else {
                    googleId && formData.append('id', googleId);
                    email && formData.append('mail', email);
                    givenName && formData.append('firstname', givenName);
                    familyName && formData.append('lastname', familyName);
                    tokenId && formData.append('token', tokenId);
                    formData.append('type', 'google');
                    socialConvert(formData);
                }
            }
        }
    };

    const socialResponseLinkedin = (response) => {
        if (response) {
            let fetch = Axios.post(`https://proxy-bpce.havasdigitalfactory.net/https://www.linkedin.com/oauth/v2/accessToken/?grant_type=authorization_code&code=${response}&redirect_uri=${host}&client_id=${linkedinClientID}&client_secret=${linkedinClientSecret}&scope=r_liteprofile+r_emailaddress`);
            fetch.then(res => {
                let { status, data } = res;
                if (status === 200 && data) {
                    const token = data.access_token;

                    if (token) {
                        let formData = new FormData();

                        let fetchEmail = Axios.get(`https://proxy-bpce.havasdigitalfactory.net/https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))&oauth2_access_token=${token}`);
                        fetchEmail.then((res) => {
                            let { status, data } = res;

                            if (status === 200 && data) {

                                const email = data.elements[0]['handle~']['emailAddress'];
                                email && formData.append('mail', email);

                                if (email) {
                                    let fetchProfile = Axios.get(`https://proxy-bpce.havasdigitalfactory.net/https://api.linkedin.com/v2/me/?projection=(id,firstName,lastName)&oauth2_access_token=${token}`);
                                    fetchProfile.then((res, token) => {
                                        let { status, data } = res;
                                        if (status === 200 && data) {
                                            const country = data.lastName.preferredLocale.country;
                                            const language = data.lastName.preferredLocale.language;
                                            const firstName = data.firstName.localized[`${language}_${country}`];
                                            const lastName = data.lastName.localized[`${language}_${country}`];

                                            if (type === 'postulate') {
                                                firstName && formData.append('firstname', firstName);
                                                lastName && formData.append('lastname', lastName);
                                                openingId && formData.append('openingid', openingId);
                                                if (openingId) {
                                                    createATSUser(formData);
                                                }
                                            } else {
                                                data.id && formData.append('id', data.id);
                                                firstName && formData.append('firstname', firstName);
                                                lastName && formData.append('lastname', lastName);
                                                token && formData.append('token', token);
                                                formData.append('type', 'linkedin');

                                                socialConvert(formData);
                                            }
                                        } else {
                                            setGlobalError('Linkedin : authentication failed');
                                        }
                                    });
                                }
                            } else {
                                setGlobalError('Linkedin : authentication failed');
                            }
                        });
                    }
                } else {
                    setGlobalError('Linkedin : authentication failed');
                }
            }
            );
        }
    };

    const socialResponseLinkedinFail = (response) => {
        if (response) {
            handleFailure('Linkedin : ' + response);
        }
    };

    const socialConvert = (formData) => {
        let fetch = Axios.post(`${site}/wp-json/bpce/v1/social/connect/`, formData);

        fetch.then(res => {
            let { data } = res;
            if (data.success) {
                handleSuccess(data.user_id);
            } else if (!data.success) {
                if (data.error) {
                    let errors = [];
                    Object.keys(data.error).map((keyName) => errors.push(data.error[keyName]));
                    handleFailure(errors.join());
                }
            }
        })
    };

    const createATSUser = (formData) => {
        let fetch = Axios.post(`${site}/wp-json/bpce/v1/user/create/ats/`, formData);

        fetch.then(res => {
            let { data } = res;
            if (data.success) {
                handleCreateATSUserSuccess();
            } else if (!data.success) {
                if (data.error) {
                    let errors = [];
                    Object.keys(data.error).map((keyName) => errors.push(data.error[keyName]));
                    handleFailure(errors.join());
                }
            }
        })
    };

    const handleCreateATSUserSuccess = () => {
        setSuccessSend(true);
        // Close popin
        setTimeout(() => {
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': false }));
        }, 1200);
    };


    const handleSuccess = (userID) => {
        setSuccessSend(true);
        setUserId(userID);
        setCookie('userID', userID, 1);

        if (type === 'alert') {
            handleAlert();
        }

        if (type === 'like' || type === 'profile') {
            // Close popin
            setTimeout(() => {
                dispatch(updateModalsOpen({ ...modalsOpen, 'login': false }));
            }, 1500);
        }

        if (type === 'cv') {
            handleCV();
        }
    };

    const handleFailure = (message) => {
        setSuccessSend(false);
        setGlobalError(message);
    };

    const renderTitle = (type, title) => {
        switch (type) {
            case 'alert':
                return title.alert;
            case 'postulate':
                return title.postulate;
            case 'like':
                return title.like;
            case 'cv':
                return title.cv;
            case 'profile':
                return title.profile;
            default:
                return "Login";
        }
    };

    const renderSuccess = (type, postulate, profile) => {
        switch (type) {
            case 'postulate':
                return <>
                    <div className="title-30 no-text"><i className="icon icon-check"></i></div>
                    <div className="title-30">{postulate}</div>
                    {/*<div className="title-30 no-text"><i className="icon icon-check"></i></div>*/}
                    {/*<CLink {...redirect} className="c-button"/>*/}
                </>;
            case 'profile':
                return <>
                    <div className="title-30 no-text"><i className="icon icon-check"></i></div>
                    <div className="title-30">{profile}</div>
                </>;
            default:
                return (
                    <div className="title-30"><i className="icon icon-check"></i></div>
                );
        }
    };

    const renderModal = () => {
        const { title, form_title, socials, separator, fields, forgot_password, submit, create_account, postulate, profile } = dataFetch;
        return (
            <Modal modalName="login" customColors={customColors}>
                {successSend ?
                    <div className="success">{renderSuccess(type, postulate, profile)}</div>
                    :
                    <>
                        {title && <h3 className="title-30">{renderTitle(type, title)}</h3>}
                        {globalError && <div className="error error--big">{globalError}</div>}
                        {(socials && Object.keys(socials).length > 0) &&
                            <>
                                <ul className="c-modal-buttons">
                                    {socials.linkedin &&
                                        <li>
                                            <LinkedinLoginButton
                                                clientId={linkedinClientID}
                                                redirectUrl={host}
                                                onSuccess={socialResponseLinkedin}
                                                onError={socialResponseLinkedinFail}
                                            >
                                                {onclick => (
                                                    <button type="button"
                                                        className="c-button c-button--linkedin"
                                                        onClick={onclick}>{socials.linkedin.title}</button>
                                                )}
                                            </LinkedinLoginButton>
                                        </li>
                                    }
                                    {socials.google &&
                                        <li>
                                            <GoogleLogin
                                                clientId={googleClientID}
                                                autoLoad={false}
                                                render={({ onClick }) => (
                                                    <button type="button"
                                                        className="c-button c-button--google"
                                                        onClick={onClick}>{socials.google.title}</button>
                                                )}
                                                onSuccess={socialResponseGoogle}
                                                onFailure={socialResponseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        </li>
                                    }
                                    {socials.facebook &&
                                        <li>
                                            <FacebookLogin
                                                appId={facebookClientID}
                                                fields="email, first_name, last_name"
                                                autoLoad={false}
                                                isMobile={false}
                                                disableMobileRedirect={true}
                                                callback={socialResponseFacebook}
                                                render={({ onClick }) => (
                                                    <button type="button"
                                                        className="c-button c-button--facebook"
                                                        onClick={onClick}>{socials.facebook.title}</button>
                                                )}
                                            />
                                        </li>
                                    }
                                </ul>

                                {(type !== 'postulate' || type !== 'alert') &&
                                    <div className="c-modal-sep">{separator && <span>{separator}</span>}<span></span></div>
                                }
                            </>
                        }
                        {(type !== 'postulate' || type !== 'alert') &&
                            <>
                                {form_title && <h4>{form_title}</h4>}

                                {(fields && Object.keys(fields).length > 0) &&
                                    <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="c-form-input" data-error={errors.loginEmail ? 'true' : 'false'}>
                                            <label htmlFor="loginEmail" className="hidden">{fields.mail.label}</label>
                                            <input type="email" placeholder={fields.mail.label} name="loginEmail"
                                                id="loginEmail"
                                                ref={register({
                                                    required: true,
                                                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
                                                })}
                                                autoComplete="noFillEmail"
                                            />
                                        </div>

                                        {errors.loginEmail ?.type === "required" &&
                                            <div className="error">{fields.mail.errors.empty}</div>}
                                        {errors.loginEmail ?.type === "pattern" &&
                                            <div className="error">{fields.mail.errors.bad}</div>}

                                        <div className="c-form-input" data-error={errors.loginPassword ? 'true' : 'false'}>
                                            <label htmlFor="loginPassword" className="hidden">{fields.password.label}</label>
                                            <input type="password" placeholder={fields.password.label} name="loginPassword"
                                                id="loginPassword"
                                                ref={register({ required: true })}
                                                autoComplete="noFillPassword" />
                                        </div>

                                        {errors.loginPassword ?.type === "required" &&
                                            <div className="error">{fields.password.errors.empty}</div>}
                                        {errors.loginPassword ?.type === "minLength" &&
                                            <div className="error">{fields.password.errors.bad}</div>}

                                        {/* <CLink {...forgot_password} /> */}
                                        <button type="button" onClick={() => handleForgotPassword()}
                                            className="c-modal-link">{forgot_password.title}</button>

                                        <button type="submit" className="c-button">{submit}</button>
                                    </form>
                                }

                                <button type="button" onClick={() => handleRegister()}
                                    className="c-modal-link">{create_account}</button>

                            </>
                        }
                    </>
                }
            </Modal>
        )
    };

    return (
        <>
            {isLoaded ? renderModal() : 'Loading'}
        </>
    )
};

export default LoginModal;