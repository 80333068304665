import React, {useState, useRef} from "react";

const Faq = ({title, list}) => {
    const [indexOpen, setIndexOpen] = useState('');

    const handleClick = (index) => {
        if (indexOpen === index) {
            // Collapase
            setIndexOpen('');
        } else {
            setIndexOpen(index);
        }
    };
    return (
        <>
            {(list && list.length > 0) &&
            <div className="c-faq">
                <h2>{title}</h2>


                <ul className="c-faq-list">
                    {list.map((item, key) => <FaqItem {...item} key={key} index={key}
                                                      handleClick={() => handleClick(key)}
                                                      active={indexOpen === key}/>)}
                </ul>

            </div>
            }
        </>
    )
};

const FaqItem = ({question, response, index, active, handleClick}) => {
    const refItem = useRef(null);

    const renderMarkup = (markup) => {
        return {__html: markup};
    };

    return (
        <li className={`c-faq-item${active ? ' opened' : ''}`} ref={refItem} data-index={index}>
            <button className="c-faq-trigger" type="button" onClick={handleClick}>
                <span>{question}</span><i
                className="icon-arrow-bottom"></i>
            </button>
            <div className="c-faq-content">
                <div className="c-faq-contentInner"
                     dangerouslySetInnerHTML={renderMarkup(response)}></div>
            </div>
        </li>
    )
};


export default Faq;