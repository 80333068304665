import React, {useEffect, useState} from "react";

// Hooks
import {useDispatch, useSelector} from 'react-redux';
import {updateGlobalStorePage, updateGlobalStorePageBis} from '../Store/action';
import useApi from '../Hooks/useApi';

// Libraries
// Import Swiper React components
import SwiperCore, {Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import {LoaderPage} from '../Components/loader';
import CLink from "../Components/link";
import Offers from "../Components/offers";
import News from "../Components/news";
import JobSectorsSlider from "../Components/job-sectors-slider";
import Testimony from "../Components/testimony";
import Ambassador from "../Components/ambassador";
import SocialWall from "../Components/social-wall";
import Video from "../Components/video";
import {Link} from "react-router-dom";
import TestimonyList from "../Components/testimony-list";
import {Caask} from "../Components/caask";


SwiperCore.use([Navigation, Pagination, Scrollbar]);

const TemplateHome = ({_uid}) => {
    let dispatch = useDispatch();
    const lang = useSelector(state => state.global).lang;

    const cookiesFilters = useSelector(state => state.global).filters;

    const [api, setApi] = useState('');

    const globalElevatorResults = useSelector(state => state.global).elevatorResults;
    let formData = new FormData();
    useEffect(() => {
        if (globalElevatorResults && Object.keys(globalElevatorResults).length > 0) {
            formData.append('lang', lang);
            formData.append('firstname', globalElevatorResults.firstname);
            formData.append('q1', globalElevatorResults.q1);
            formData.append('q2', globalElevatorResults.q2);
            formData.append('q3', globalElevatorResults.q3);
            formData.append('q4', globalElevatorResults.q4);
            formData.append('q5', globalElevatorResults.q5);

            setApi({
                name: 'elevator/personalize',
                method: 'post',
                data: formData
            });
        } else {
            setApi({name: 'home', _uid: _uid});
        }
    }, [dispatch, globalElevatorResults]); // eslint-disable-line


    const [dataFetch, isLoaded] = useApi(api);

    useEffect(() => {
        dispatch(updateGlobalStorePage('t-home'));
        dispatch(updateGlobalStorePageBis(''));
    }, [dispatch]); // isLoaded


    function renderPage() {
        const {metas, bloc_2_cards, bloc_2_cards_bottom, jobs_offer, news, sectors, commitment, ambassadors, socials, main_ambassord_testimony, testimonies, sectors_elevator, career, path_filtered} = dataFetch;


        return (
            <>
                <Metatags {...metas} />
                <Caask/>
                <main className="main">
                    {(bloc_2_cards && Object.keys(bloc_2_cards).length > 0) &&
                    <HomeCards {...bloc_2_cards}/>
                    }

                    {(jobs_offer && Object.keys(jobs_offer).length > 0) &&
                    <Offers {...jobs_offer} map={false} path_filtered={path_filtered}
                            cookies_filtered={cookiesFilters && cookiesFilters.home} src="home"/>
                    }

                    {((window.location.href.indexOf(process.env.REACT_APP_DOMAIN_1) > -1 || window.location.hostname === "localhost") && lang === 'fr') &&
                    <div className="c-caask-mobile"/>
                    }


                    <Group/>

                    {(main_ambassord_testimony && Object.keys(main_ambassord_testimony).length > 0) &&
                    <HomeAmbassador {...main_ambassord_testimony}/>
                    }

                    {(news && Object.keys(news).length > 0) &&
                    <News {...news}/>
                    }

                    {sectors_elevator && Object.keys(sectors_elevator).length > 0 &&
                    <HomeJobs {...sectors_elevator}/>
                    }

                    {(sectors && Object.keys(sectors).length > 0) &&
                    <JobSectorsSlider {...sectors}/>
                    }

                    {(commitment && Object.keys(commitment).length > 0) &&
                    <HomeTestimony {...commitment}/>
                    }

                    {(ambassadors && Object.keys(ambassadors).length > 0) &&
                    <HomeAmbassadors {...ambassadors}/>
                    }

                    {(testimonies && testimonies.items && testimonies.items.length > 0) &&
                    <TestimonyList {...testimonies}/>
                    }

                    {(career && Object.keys(career).length > 0) &&
                    <HomeCareer {...career}/>
                    }

                    {(bloc_2_cards_bottom && Object.keys(bloc_2_cards_bottom).length > 0) &&
                    <HomeCards {...bloc_2_cards_bottom}/>
                    }


                    {(socials && Object.keys(socials).length > 0) &&
                    <SocialWall {...socials}/>
                    }
                </main>
            </>
        )
    }


    return isLoaded ? renderPage() :
        <LoaderPage/>
};

export default TemplateHome;

const HomeCards = ({imgFilter, items}) => {

    function hex_is_light(color) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
    }

    const [darkColor, setDarkColor] = useState(imgFilter ? hex_is_light(imgFilter) : false);

    return (
        <>
            {(items && items.length > 0) &&
            <div className="c-cards">
                <div className="container">
                    <div className={`c-cards__list${imgFilter ? ' c-cards__list--filter' : ''}`} data-dark={darkColor}>
                        {
                            items.map(({type, title, image, link, subtitle, youtube_id}, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        {type === 'video' ?
                                            <div className="c-cards__item">
                                                <Video youtubeID={youtube_id} image={image} title={title}
                                                       imgFilter={imgFilter}/>
                                            </div>
                                            :
                                            <React.Fragment>
                                                {link && link.internal ?
                                                    <Link to={link.url} title={link.title} target={link.target}
                                                          className="c-cards__item"
                                                          style={{backgroundImage: `url(${image.src ? image.src : ''})`}}>
                                                        {imgFilter && <div className="c-cards__item-filter"
                                                                           style={{backgroundColor: imgFilter}}/>}
                                                        {title && <h3 className="title-40">{title}</h3>}
                                                        {subtitle && <p>{subtitle}</p>}
                                                    </Link>
                                                    :
                                                    <a href={link.url} title={link.title} target={link.target}
                                                       className="c-cards__item"
                                                       style={{backgroundImage: `url(${image.src ? image.src : ''})`}}>
                                                        {imgFilter && <div className="c-cards__item-filter"
                                                                           style={{backgroundColor: imgFilter}}/>}
                                                        {title && <h3 className="title-40">{title}</h3>}
                                                        {subtitle && <p>{subtitle}</p>}
                                                    </a>
                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            }
        </>
    )
};

const Group = () => {
    let dataGlobal = useSelector(state => state.global).global;

    // DESTRUCTURING
    const {baseline, companies} = dataGlobal.pre_footer;

    return (
        <>
            {(baseline || companies) &&
            <div className="c-group" data-bg="grey">
                <div className="container">
                    {(baseline && Object.keys(baseline).length > 0) &&
                    <p className="c-group-intro"><span className="title-50">{baseline.strong}</span> <span
                        dangerouslySetInnerHTML={{__html: baseline.text}}></span>
                    </p>}
                    {(companies && companies.length > 0) &&
                    <ul className="c-group-logos">
                        {
                            companies.map(({link, logo}, key) => {
                                return (
                                    <li key={key}>
                                        <CLink {...link}>
                                            <img src={logo.image.src}
                                                 alt={logo.image.alt}/>
                                        </CLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    }
                </div>
            </div>
            }
        </>
    )
};

const HomeTestimony = ({quote, text, link, testimony}) => {
    return (
        <>
            {(quote || (testimony && Object.keys(testimony).length > 0)) &&
            <div className="home-testimony">
                <div className="container">
                    <div className="home-testimonyFlex">
                        <div className="home-testimony-content">
                            {quote && <blockquote>{quote}</blockquote>}
                            {text && <p>{text}</p>}
                            {(link && Object.keys(link).length > 0) &&
                            <div className="home-testimony-button">
                                <CLink {...link} className={`c-button`}/>
                            </div>
                            }
                        </div>
                        <div className="home-testimony-item">
                            {(testimony && Object.keys(testimony).length > 0) &&
                            <Testimony {...testimony}/>
                            }
                        </div>
                    </div>

                </div>
            </div>
            }
        </>
    )
};

const HomeAmbassadors = ({title, logo, list, link}) => {
    let {m1024} = useSelector(state => state.breakpoint);

    return (
        <>
            {(list && list.length > 0) &&
            <div className="home-ambassadors">
                <div className="container">
                    <div className="title-50">
                        {title && <span>{title}</span>}
                        {(logo && Object.keys(logo).length > 0) &&
                        <img src={logo.src} alt={logo.alt}/>
                        }
                    </div>


                    <>
                        {m1024 ?
                            <div className="home-ambassadors-sliderCtn">

                                <Swiper className="home-ambassadors-slider"
                                        spaceBetween={20}
                                        speed={100}
                                        autoHeight={true}
                                        pagination={{clickable: true}}
                                        slidesPerView={1}
                                        grabCursor={true}
                                        noSwiping={false}
                                        breakpoints={{
                                            768: {
                                                spaceBetween: 22,
                                                slidesPerView: 2,
                                            }
                                        }}
                                >
                                    {
                                        list.map((item, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Ambassador testimony={item}/>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                            :
                            <div className="home-ambassadorsFlex">
                                {
                                    list.map((item, key) => {
                                        return (
                                            <Ambassador testimony={item} key={key}/>
                                        )
                                    })
                                }
                            </div>
                        }
                    </>

                    {(link && Object.keys(link).length > 0) &&
                    <div className="home-ambassadors-button">
                        {link.title &&
                        <CLink {...link} className={`c-button`}/>
                        }
                    </div>
                    }
                </div>
            </div>
            }
        </>
    )
};

const HomeAmbassador = ({title, testimony, link}) => {
    return (
        <div className="home-ambassador">
            <div className="container">
                <Ambassador title={title} testimony={testimony} link={link}/>
            </div>
        </div>
    )
};

const HomeJobs = ({title, list, link, testimony}) => {
    return (
        <>
            {(list || testimony) &&
            <div className="home-jobs">
                <div className="container">
                    <div className="home-jobsFlex">
                        <div className="home-jobs-content">
                            {title && <h2 className="title-50">{title}</h2>}
                            {list &&
                            <ul className="buttons-list">
                                {list.map(({title, link}, key) => {
                                        return (
                                            <li key={key}>
                                                <CLink {...link} className="c-button">{title}</CLink>
                                            </li>
                                        )
                                    }
                                )}
                            </ul>
                            }
                            {(link && Object.keys(link).length > 0) &&
                            <div className="home-jobs-button">
                                <CLink {...link} className="c-button"/>
                            </div>
                            }
                        </div>
                        <div className="home-jobs-item">
                            {(testimony && Object.keys(testimony).length > 0) &&
                            <Testimony {...testimony}/>
                            }
                        </div>
                    </div>

                </div>
            </div>
            }
        </>
    )
};

const HomeCareer = ({title, page, quote, text, cta}) => {
    return (
        <>
            {Object.keys(page).length &&
            <div className="home-career">
                <div className="container">
                    {title && <h2 className="title-50">{title}</h2>}
                    <div className="home-careerFlex">
                        <div className="home-career-item">
                            {page.title && <div className="title-40">{page.title}</div>}
                            {page.excerpt && <p>{page.excerpt}</p>}
                            {(page.link && Object.keys(page.link).length > 0) &&
                            <CLink {...page.link} className="c-button"/>}
                        </div>
                        <div className="home-career-content">
                            {quote && <blockquote>{quote}</blockquote>}
                            {text && <p>{text}</p>}
                            {(cta && Object.keys(cta).length > 0) &&
                            <div className="home-career-button">
                                <CLink {...cta} className="c-button"/>
                            </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
            }
        </>
    )
};