import React from 'react';

// Import Swiper React components
import SwiperCore, {Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import CLink from "../Components/link";

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const FSlider = ({index, title, carousel}) => {

    const ReduceImg = () => {
        const allImg = document.querySelectorAll(".item-img");
        allImg.forEach((img) => {
            if (!img.classList.contains('reduce')) {
                img.classList.add('reduce');
            }
        })
    };

    const IncreaseImg = () => {
        const allImg = document.querySelectorAll(".item-img");
        allImg.forEach((img) => {
            if (img.classList.contains('reduce')) {
                img.classList.remove('reduce');
            }
        })
    };

    return (
        <div className="flexible f-slider margincontrol" data-bg={(index % 2 === 0) ? 'grey' : 'white'}>
            <div className="container">
                <h2 className="title-40">{title}</h2>
                {carousel.length > 0 &&
                <Swiper className="f-slider-items"
                        spaceBetween={10}
                        speed={100}
                        navigation
                        autoHeight={true}
                        pagination={{clickable: true}}
                        slidesPerView={1}
                        grabCursor={true}
                        noSwiping={false}
                        scrollbar={{draggable: true}}
                        onSliderMove={() => ReduceImg()}
                        onTouchEnd={() => IncreaseImg()}
                        freeMode={false}
                        resistance={false}
                        freeModeMomentumRatio={1}
                        freeModeMomentumVelocityRatio={0.5}
                        freeModeMomentumBounceRatio={1}
                        breakpoints={{
                            1025: {
                                spaceBetween: 22,
                                freeMode: true,
                                slidesPerView: 'auto',
                                autoHeight: false
                            }
                        }}
                >

                    {
                        carousel.map(({image, link}, key) => {
                            return (
                                <SwiperSlide key={key}>
                                    {(link && Object.keys(link).length > 0) ?
                                        <CLink {...link}>
                                            <img className="item-img" src={image.src} alt={image.alt}/>
                                        </CLink>
                                        :
                                        <img className="item-img" src={image.src} alt={image.alt}/>
                                    }
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                }
            </div>
        </div>
    )
};

export default FSlider;