import React, {useEffect} from "react";
import {Route, useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {updatePathFr, updatePathEn, updatePreviousLocation, updateReferrer} from "../../Store/action";

// templates
import TemplateContent from '../../Templates/TemplateContent';
import TemplateJobSectors from "../../Templates/TemplateJobSectors";
import TemplateJobList from "../../Templates/TemplateJobList";
import TemplateJobDetails from "../../Templates/TemplateJobDetails";
import TemplateTestimonyList from "../../Templates/TemplateTestimonyList";
import TemplateTestimonyDetails from "../../Templates/TemplateTestimonyDetails";
import TemplateOfferList from "../../Templates/TemplateOfferList";
import TemplateOfferDetails from "../../Templates/TemplateOfferDetails";
import TemplateSearch from "../../Templates/TemplateSearch";
import Template404 from '../../Templates/Template404';
import TemplateHome from "../../Templates/TemplateHome";
import TemplateUnsubscribeAlert from "../../Templates/TemplateUnsubscribeAlert";
import TemplateHomeFlexible from "../../Templates/TemplateHomeFlexible";

/**
 * @description à modifier suivant les projets
 */
const DynamicTemplate = {
    'Template Contenu': TemplateContent,
    'Template Secteurs': TemplateJobSectors,
    'Template Metiers': TemplateJobList,
    'Template Profession': TemplateJobDetails,
    'Template Temoignages': TemplateTestimonyList,
    'Template Temoignage': TemplateTestimonyDetails,
    'Template Offres emploi': TemplateOfferList,
    'Template Job': TemplateOfferDetails,
    'Template Search': TemplateSearch,
    'Template 404': Template404,
    'Template Accueil': TemplateHome,
    'Template Home Flexible': TemplateHomeFlexible,
    'Template Unsubscribe alert': TemplateUnsubscribeAlert
};

/**
 * @component
 * @description crer des routes en fonction des données JSON et utilise la correspondance nom côté back // nom component côté front
 * @param {object} route data des routes
 */
function RouteForTemplates(route) {
    let lang = useSelector(state => state.global).lang;
    let history = useHistory();
    let search = useLocation().search; // si recherche type http://monurl.com/recherche?=texte
    let hash = useLocation().hash; // si recherche avec # dans l'url

    let page = useSelector(state => state.page).page;
    let previousLocation = useSelector(state => state.global).previousLocation;
    // let referrer = useSelector(state => state.global).referrer;
    let dispatch = useDispatch();
    useEffect(() => {
        // console.log('document.referrer : ' + document.referrer);
        // console.log('window.location.href : ' + window.location.href);
        // console.log('initial referrer store : ' + referrer);
        // console.log('previousLocation store : ' + previousLocation);
        if (document.referrer !== window.location.href) {
            //console.log('0');
            if (previousLocation === '') {
                //console.log('0a');
                if (document.referrer !== '') {
                    //console.log('0a1');
                    dispatch(updateReferrer(document.referrer));
                } else {
                    //console.log('0a2');
                    dispatch(updateReferrer('undefined'));
                }
            } else {
                //console.log('0b');
                dispatch(updateReferrer(previousLocation));
            }
        } else {
            // console.log('1');
            dispatch(updateReferrer('undefined'));
        }
        return () => {
            dispatch(updatePreviousLocation(window.location.href));
        }
    }, [page]); // eslint-disable-line

    // useEffect(() => {
    //     console.log('updated routes referrer store : ' + referrer);
    // }, [referrer]);
    // useEffect(() => {
    //     console.log('updated routes previousLocation store : ' + previousLocation);
    // }, [previousLocation]);

    useEffect(
        () => {
            //console.log('ROUTE',route);
            switch (lang) {
                case 'fr':
                    if(route.path_en && route.path_en !== ""){
                        dispatch(updatePathEn(route.path_en));
                    } else {
                        dispatch(updatePathEn(null));
                    }
                    history.replace({
                        pathname: route.path_fr, search, hash
                    });
                    break;
                case 'en':
                    if(route.path && route.path !== ""){
                        dispatch(updatePathFr(route.path));
                    } else {
                        dispatch(updatePathFr(null));
                    }
                    history.replace({
                        pathname: route.path_en, search, hash
                    });
                    break;
                default:
                    break;
            }
        }, [lang]); // eslint-disable-line

    return (
        <Route path={route['path_' + lang]} name={route.component} exact={route.exact} render={() =>
            React.createElement(DynamicTemplate[route.component], {
                key: route._uid,
                ...route.datas,
                _uid: route._uid
            })
        }/>
    );
}

export default RouteForTemplates;