import React, {useState} from "react";

// Hooks
import useApi from "../../Hooks/useApi";
import {useSelector} from "react-redux";

// Libraries
import {Controller, useForm} from "react-hook-form";
import Axios from "axios";
import Select from "react-select";

// Components
import Modal from "./modal";


const AlertModal = ({customColors}) => {
    const userID = useSelector(state => state.global).userId;

    // multisite : get current site
    let site = useSelector(state => state.global).site;

    const [dataFetch, isLoaded] = useApi({name: 'jobs/popin/alert'});

    const {handleSubmit, setValue, errors, control} = useForm();

    let [successSend, setSuccessSend] = useState(false);
    let [globalError, setGlobalError] = useState(null);
    let [message, setMessage] = useState(null);

    const onSubmit = (data) => {
        setGlobalError(null);

        let formData = new FormData();

        formData.append('uid', userID);

        let sectorArray = [];
        if (data.tax_sector) {
            data.tax_sector.map((tax_sector) => sectorArray.push(tax_sector.value));
        }
        formData.append('tax_sector', sectorArray.join());

        let tax_contractArray = [];
        if (data.tax_contract) {
            data.tax_contract.map((tax_contract) => tax_contractArray.push(tax_contract.value));
        }
        formData.append('tax_contract', tax_contractArray.join());

        let tax_placeArray = [];
        if (data.tax_place) {
            data.tax_place.map((tax_place) => tax_placeArray.push(tax_place.value));
        }
        formData.append('tax_place', tax_placeArray.join());


        let tax_experienceArray = [];
        if (data.tax_experience) {
            data.tax_experience.map((tax_experience) => tax_experienceArray.push(tax_experience.value));
        }
        formData.append('tax_experience', tax_experienceArray.join());

        let tax_degreeArray = [];
        if (data.tax_degree) {
            data.tax_degree.map((tax_degree) => tax_degreeArray.push(tax_degree.value));
        }
        formData.append('tax_degree', tax_degreeArray.join());


        let tax_brandsArray = [];
        if (data.tax_brands) {
            data.tax_brands.map((tax_brands) => tax_brandsArray.push(tax_brands.value));
        }
        formData.append('tax_brands', tax_brandsArray.join());


        let fetch = Axios.post(`${site}/wp-json/bpce/v1/jobs/add/alert/`, formData);

        fetch.then(res => {
            let {status, data} = res;

            if (data.success && status === 200 && !Object.keys(errors).length) {
                setSuccessSend(true);
                setMessage(data.message)
            } else if (!data.success) {
                setSuccessSend(false);
                setGlobalError(data.message);
            }
        })
    };

    const renderModal = () => {
        const {title, subtitle, filters, button} = dataFetch;
        return (
            <Modal modalName="alert" customColors={customColors}>
                {successSend ?
                    <div className="success">
                        <div className="title-30"><i className="icon icon-check"></i>{message}</div>
                    </div>
                    :
                    <>
                        {title && <h3 className="title-30">{title}</h3>}
                        {subtitle && <p>{subtitle}</p>}
                        {globalError && <div className="error error--big">{globalError}</div>}
                        {(filters && filters.items && filters.items.length) &&
                        <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                            {filters.items.map(({title, id, choices}, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        {id !== 'tax_channel' &&
                                        <div className="c-form-selectCtn">
                                            <label htmlFor={id} className="hidden">{title}</label>
                                            <Controller
                                                control={control}
                                                name={id}
                                                render={() => (
                                                    <Select options={choices} placeholder={title}
                                                            className="c-form-select"
                                                            id={id}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            isMulti={true}
                                                            isSelected={false}
                                                            classNamePrefix="c-form-select"
                                                            data-size="compact"
                                                            onChange={val => {
                                                                setValue(id, val);
                                                            }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        }
                                    </React.Fragment>
                                )
                            })
                            }


                            <button type="submit" className="c-button">{button}</button>
                        </form>
                        }
                    </>
                }
            </Modal>
        )
    };

    return (
        <>
            {isLoaded ? renderModal() : 'Loading'}
        </>
    )
};

export default AlertModal;