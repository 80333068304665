import {useState, useEffect} from 'react';
import Axios from 'axios';
import {useSelector} from 'react-redux';

/**
 * @async Creation d'un hook d'appel de l'API Search
 * @param {string} value nom de l'API
 * @returns {[Array, Boolean]} data Datafetch = [JSON], isLoaded = false
 */

export default ({value}) => {

    let site = useSelector(state => state.global).site;

    const [dataFetch, setDataFetch] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(
        () => {
            if(value !== '') {
                setIsLoaded(false);
                let load = false;

                const fetchAPi = async () => {
                    try {
                        const results = await Axios(
                            `${site}/wp-json/${process.env.REACT_APP_API_NAME}/v1/search/${value}/from/0`
                        );

                        if (!load) setDataFetch(results.data);
                        if (!load) setIsLoaded(true);
                       // console.log(results.data);
                    } catch (err) {
                        // console.err(err)
                    }
                };
                fetchAPi();

                return () => {
                    load = true;
                };
            }
        },
        [value, site]
    );

    // si l'api renvoi un string on le converti en JSON avant de retourner
    return [typeof dataFetch == 'string' ? JSON.parse(dataFetch) : dataFetch, isLoaded];
};