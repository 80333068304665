// Hooks
import useScript from "../Hooks/useScript";

export const Caask = () => {
    useScript('https://www.app.caask.co/bpce/scripts/home', 'caask-script-home');
    return null;
};

export const CaaskAllOffers = () => {
    useScript('https://www.app.caask.co/bpce/scripts/list_offers', 'caask-script-all-offers');
    return null;
};

export const CaaskOfferDetails = () => {
    useScript('https://www.app.caask.co/bpce/scripts/offer', 'caask-script-offer-details');
    return null;
};

