import {useState, useEffect} from 'react';
import Axios from 'axios';
import {useSelector} from 'react-redux';

/**
 * @async Creation d'un hook d'appel des API
 * **Utilisation sur Team, Agenda, Videos, Contacts, Article, Search**
 * @param {string} name nom de l'API
 * @param {string} _uid ID du template
 * @param {string} slug nom d'article
 * @param {string} termOfSearch terme à rechercher ?s=
 * @param {string} method post ou get
 * @param {formData} data formData
 * @param {string} params filtres pour la page offers &taxonomie[]=value&taxonomie2[]=value2
 * @returns {[Array, Boolean]} data Datafetch = [JSON], isLoaded = false
 */

export default ({name = '', slug, _uid, termOfSearch, params = '', method = 'get', data = ''}) => {


    const lang = useSelector(state => state.global).lang;
    const userID = useSelector(state => state.global).userId;
    let site = useSelector(state => state.global).site;

    const [dataFetch, setDataFetch] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(
        () => {
            if (name && name !== '') {
                setIsLoaded(false);
                let load = false;

                const fetchAPi = async () => {
                    try {
                        const dataFetch = await Axios[method](
                            `${site}/wp-json/${process.env.REACT_APP_API_NAME}/v1/${name}/${lang && method !== "post" ? `?lang=${lang}` : ''}${userID && method === "post" ? `?userID=${userID}` : ''}${userID && method !== "post" ? `&userID=${userID}` : ''}${slug ? `&slug=${slug}` : ''}${_uid ? '&_uid=' + _uid : ''}${params ? params : ''}`, data
                        );

                        if (!load) setDataFetch(dataFetch.data);
                        if (!load) setIsLoaded(true);
                        console.log(name, dataFetch.config.url, dataFetch.data);
                    } catch (err) {
                        //  console.err(err)
                    }
                };
                fetchAPi();

                return () => {
                    load = true;
                };
            }
        },
        [lang, name, _uid, slug, termOfSearch, params] // eslint-disable-line
    );

    // si l'api renvoi un string on le converti en JSON avant de retourner
    return [typeof dataFetch === 'string' ? JSON.parse(dataFetch) : dataFetch, isLoaded];

};
