import React from 'react';
// import {Loader} from '../../Components/loader.jsx';

import FText from '../../Flexibles/text';
import FTextImg from '../../Flexibles/text-img';
import FVideo from "../../Flexibles/video";
import FImg from "../../Flexibles/img";
import FSlider from '../../Flexibles/slider';
import FHomeJobs from '../../FlexiblesHome/home-jobs';
import FHomeNews from '../../FlexiblesHome/home-news';
import FHomeCards from '../../FlexiblesHome/home-cards';
import FHomeTestimony from '../../FlexiblesHome/home-testimony';
import FHomeAmbassadors from '../../FlexiblesHome/home-ambassadors';
import FHomeTestimonies from '../../FlexiblesHome/home-testimonies';
import FHomeSocialwall from '../../FlexiblesHome/home-socialwall';
import FHomeGroup from '../../FlexiblesHome/home-group';
import FMargin from '../../Flexibles/margin';
import FImgFull from '../../Flexibles/img-full';
import FChapeau from '../../Flexibles/chapeau';
import FHomeOffers from '../../FlexiblesHome/home-offers';
//import FHomeElevator from '../../FlexiblesHome/home-elevator';

/**
 * Déclaration de la correspondance nom reçu / nom du composant
 *
 */
const DynamicComponents = {
    bloc_wysiwyg: FText,
    bloc_texte_image: FTextImg,
    bloc_video: FVideo,
    bloc_carrousel: FSlider,
    bloc_image: FImg,
    bloc_sectors: FHomeJobs,
    bloc_news: FHomeNews,
    bloc_2_cards: FHomeCards,
    bloc_commitment: FHomeTestimony,
    bloc_ambassadors: FHomeAmbassadors,
    bloc_testimonies: FHomeTestimonies,
    bloc_social_networks: FHomeSocialwall,
    bloc_separator_text: FHomeGroup,
    bloc_margin: FMargin,
    bloc_image_full: FImgFull,
    bloc_chapo: FChapeau,
    bloc_jobs: FHomeOffers
    //bloc_pitch_elevator: FHomeElevator
};

/**
 * ## Generation des composants dynamiquement suivant JSON
 * @param {object} block
 * cf : https://www.storyblok.com/tp/react-dynamic-component-from-json
 */
const ComponentByJSON = (block, index) => {
    if (typeof DynamicComponents[block.bloc] !== 'undefined') {
        return React.createElement(DynamicComponents[block.bloc], {
            key: block._uid,
            index: index,
            ...block.datas
        });
    }
    // component doesn't exist yet
    return React.createElement(() => <div className="container"><p>{block.bloc} : Not yet or wrong
        name!</p></div>, { key: block._uid });
    // return React.createElement(() => <Loader/>, {key: block._uid});
};
export default ComponentByJSON;