import React from "react";

export const Loader = () => {
    return (
        <div className="loader">
            <img src={require('../Assets/img/loader.svg')} alt=""/>
        </div>
    )
};

export const LoaderPage = () => {
    return <div className="loading-page">
        <div className="loading_headImg"></div>
        <div className="layout">
            <div className="container">
                <div className="loading_inner">
                    <div className="loading">
                        <div className="blank blank_title"></div>
                        <div className="shape shape_title"></div>
                        <div className="blank blank_intro"></div>
                        <div className="shape shape_intro"></div>
                    </div>
                </div>
                <div className="loading">
                    <div className="blank blank_fat"></div>
                    <div className="shape shape_fat"></div>
                    <div className="blank blank_text"></div>
                    <div className="shape shape_text"></div>
                </div>
            </div>
        </div>
    </div>
};

export const LoaderResults = () => {
    return <div className="loading-page">
        <div className="layout">
            <div className="container">
                <div className="loading">
                    <div className="shape shape_title"></div>
                    <div className="blank blank_title"></div>
                    <div className="shape shape_title"></div>
                    <div className="blank blank_title"></div>
                    <div className="shape shape_title"></div>
                    <div className="blank blank_title"></div>
                    <div className="shape shape_title"></div>
                    <div className="blank blank_title"></div>
                    <div className="shape shape_title"></div>
                    <div className="blank blank_title"></div>
                    <div className="shape shape_title"></div>
                    <div className="blank blank_title"></div>
                    <div className="shape shape_title"></div>
                    <div className="shape shape_title"></div>
                </div>
            </div>
        </div>
    </div>
};