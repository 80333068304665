import React, {useEffect, useRef, useMemo} from "react";
import propTypes from "prop-types";
import { createPortal } from 'react-dom';

// Hooks
import {useDispatch, useSelector} from "react-redux";
import {updateModalsOpen} from "../../Store/action";

const Modal = ({modalName, children, customColors}) => {

    let dispatch = useDispatch();

    const modalEl = useRef();

    const modalsOpen = useSelector(state => state.modals).openModal;

    const isShow = useMemo(() => !!modalsOpen[modalName], [modalsOpen, modalName]);

    useEffect(() => {
        if(isShow) {
            document.body.style.overflow = 'hidden';
        } 
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [isShow])

    const closeModal = () => {
        const newState = {
            ...modalsOpen,
            [modalName]: false
        };
        dispatch(updateModalsOpen(newState));
    };

    return isShow
        ? createPortal(
            <div className={`c-modal fade c-modal--${modalName} ${modalsOpen[modalName] ? 'show' : ''}${customColors ? ' customColors': ''}`}>
                <div className={`c-modal-backdrop fade ${modalsOpen[modalName] ? 'show' : ''}`}
                     onClick={closeModal}>&nbsp;
                </div>
                <div className="c-modal-dialog">
                    <div className="c-modal-content" ref={modalEl}>
                        <button type="button" className="c-modal-close" data-dismiss="modal" aria-label="Close"
                                onClick={closeModal}><i className="icon-close"></i></button>
                        <div className="c-modal-body">{children}</div>
                    </div>
                </div>
            </div>,
        document.body
    ) : null
};

Modal.propTypes = {
    modalName: propTypes.string,
    children: propTypes.node,
}

export default Modal;