import React, {useEffect, useState} from "react";

// Hooks
import {useDispatch, useSelector} from 'react-redux';
import {updateGlobalStorePage, updateGlobalStorePageBis} from '../Store/action';
import useApi from '../Hooks/useApi';

// Libraries
import Select from "react-select";

// Components
import {LoaderPage} from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import MainHeader from "../Layout/main/header";
import TestimonyList from "../Components/testimony-list";
import SearchOffers from "../Components/search-offers";
import JobSectorsSlider from "../Components/job-sectors-slider";


const TemplateTestimonyList = ({_uid}) => {

    const lang = useSelector(state => state.global).lang;
    const userID = useSelector(state => state.global).userId;

    const [dataFetch, isLoaded] = useApi({name: 'pages', _uid: _uid});

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-testimony-list'));
        dispatch(updateGlobalStorePageBis(''));
    }, [dispatch]); // isLoaded

    // Save params for query
    const [value, setValue] = useState('');
    const [searchPath, setSearchPath] = useState('');
    const [params, setParams] = useState({
        'lang': lang,
        'keyword': value,
        'tax_sector': '',
        'tax_contract': '',
        'tax_place': '',
        'tax_job': '',
        'tax_experience': '',
        'tax_degree': '',
        'tax_brands': '',
        'tax_department': '',
        'tax_city': '',
        'tax_country': '',
        'tax_channel': '',
        'jobcode': '',
        'tax_community_job': '',
        'external': false,
        'userID': userID !== null ? userID : '',
        "from": 0,
        'size': 9
    });

    // Event when input search is updated
    const handleChange = (e) => {
        setValue(e);
        setParams({
            ...params,
            keyword: e
        });
    };

    const handleFilters = (e, id) => {
        let values = [];
        e && e.map(({value}) => values.push(value));
        setParams({
            ...params,
            [id]: values.join()
        })
    };

    useEffect(() => {
        let index = 0;
        let path = '';
        for (const filter in params) { // loop sur les params
            if (params[filter] !== '') {// je ne prends que les paramètres remplis sauf lang et userID
                if (index === 0) {
                    path = `?${filter}=${params[filter]}`;
                } else {
                    path += `&${filter}=${params[filter]}`
                }
                index++;
            }
        }
        setSearchPath(path);
    }, [params]);

    const renderPage = () => {
        const {metas, content: {top: {title, introduction, filters}, main: {testimonies}, bottom: {discover_jobs, sectors}}} = dataFetch;

        return (
            <>
                <Metatags {...metas} />

                <main className="main">

                    <MainHeader title={title} introduction={introduction} color='blue'/>

                    <div className="main__content">
                        <TestimonyListContent filters={filters} testimonies={testimonies}/>
                    </div>

                    {(discover_jobs && Object.keys(discover_jobs).length > 0) &&
                    <SearchOffers {...discover_jobs} handleChange={handleChange} handleFilters={handleFilters}
                                  searchPath={searchPath}/>
                    }

                    {(sectors && Object.keys(sectors).length > 0) &&
                    <JobSectorsSlider {...sectors}/>
                    }

                </main>
            </>
        )
    };

    return isLoaded ? renderPage() : <LoaderPage/>
};

export default TemplateTestimonyList;


const TestimonyListContent = ({filters, testimonies}) => {
    const [results, setResults] = useState(testimonies.items);

    const [filterList, setFilterList] = useState({
        tax_sector: '',
        tax_brands: ''
    });

    useEffect(() => {
        applyFilterList();
    }, [filterList]); // eslint-disable-line

    const applyFilterList = () => {
        let firstFilter = [];
        let testimoniesResults = [];

        if (filterList.tax_sector === "" && filterList.tax_brands === "") {
            testimoniesResults = testimonies.items;
        } else {
            // check filter sector
            filterList.tax_sector !== "" ? (
                testimonies.items.filter(item =>
                    item.tags.tax_sector === filterList.tax_sector && firstFilter.push(item))
            ) : firstFilter = testimonies.items;

            // check filter brands
            filterList.tax_brands !== "" ? (
                firstFilter.filter(item =>
                    item.tags.tax_brands === filterList.tax_brands && testimoniesResults.push(item))
            ) : testimoniesResults = firstFilter;
        }

        setResults(testimoniesResults);
    };

    const handleFilter = (e, id) => {
        setFilterList({...filterList, [id]: e ? e.value : ''});
    };

    return (
        <>
            {(filters.items && filters.items.length > 0) &&
            <div className="c-filters" data-color="blue">
                <div className="container">
                    <div className="c-filtersFlex">
                        {filters.title &&
                        <div className="c-filters-label">{filters.title}&nbsp;:</div>
                        }
                        {filters.items.map(({title, id, choices}, key) => {
                            return (
                                <div className="c-form-selectCtn" key={key}>
                                    <label htmlFor={'testimony_' + id} className="hidden">{title}</label>
                                    <Select options={choices} placeholder={title} className="c-form-select"
                                            id={'testimony_' + id}
                                            isClearable
                                            classNamePrefix="c-form-select" onChange={(e) => handleFilter(e, id)}/>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
            }

            {
                (testimonies && testimonies.items && testimonies.items.length > 0) &&
                <TestimonyList {...testimonies} hasLoadmore={true} results={results} link={testimonies.link}/>
            }
        </>
    )
};