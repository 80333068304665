import './Styles/App.scss';

import React, { useEffect, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';

// HOOKS
import useApi from './Hooks/useApi';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateGlobalStoreGlobal,
    updateGlobalisLoaded,
    updateGlobalStoreRoutes,
    updateGlobalStoreElevator,
    updateGlobalYoutube,
    updateGlobalStoreLang,
    updateGlobalStoreSite,
    updateGlobalUser,
    updateIs767,
    updateIs991,
    updateIs1024,
    updateIs1199,
    updateElevatorResults, updateGlobalisRestricted,
    // updateGA,
    updateDataDriven,
    updateFilters
} from './Store/action';

// TPL PARTS
import Header from './Layout/header/header';
import Footer from './Layout/footer/footer';

// MODALS
import LoginModal from "./Components/modals/login-modal";
import RegisterModal from "./Components/modals/register-modal";
import AlertModal from "./Components/modals/alert-modal";
// import CVModal from "./Components/modals/cv-modal";
import ForgotPasswordModal from "./Components/modals/forgot-password-modal";
import ResetPasswordModal from "./Components/modals/reset-password-modal";

// GA
// import ReactGA from 'react-ga';

// Utils
import { getCookie } from "./Utils/cookies";

// Components
import RouteForTemplates from './Layout/routes/route-for-templates';
import { LoaderPage } from "./Components/loader";
import ScrollToTop from './Components/ScrollToTop';

/**
 * @param {string} valueToSearch
 */
function urlSearchParams(valueToSearch) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(valueToSearch);
}

/**
 * @param {string} valueToMatch
 * @param {function} callback
 */
function processRelatedEnvLogic(valueToMatch, callback) {
    if (valueToMatch) {
        for (let i = 0; i < 999; i++) {
            const environnement = process.env;
            // console.log(valueToMatch, eval(`environnement.REACT_APP_DOMAIN_${i}`));
            if (valueToMatch === eval(`environnement.REACT_APP_DOMAIN_${i}`)) {
                if (i === 1) {
                    callback({
                        env: environnement.REACT_APP_APP,
                        //gtag: environnement.REACT_APP_GTAG
                    });
                } else {
                    callback({
                        env: eval(`environnement.REACT_APP_APP_${i}`),
                        // gtag: eval(`environnement.REACT_APP_GTAG_${i}`)
                    });
                }
                return;
            }
        }
    }


    /*  switch (valueToMatch) {
          case process.env.REACT_APP_DOMAIN_1:
              callback({env: process.env.REACT_APP_APP, gtag: process.env.REACT_APP_GTAG});    // site Recrutement
              break;
          case process.env.REACT_APP_DOMAIN_2:
              callback({env: process.env.REACT_APP_APP_2, gtag: process.env.REACT_APP_GTAG_2});
              break;
          case process.env.REACT_APP_DOMAIN_3:
              callback({env: process.env.REACT_APP_APP_3, gtag: process.env.REACT_APP_GTAG_3});
              break;
          case process.env.REACT_APP_DOMAIN_4:
              callback({env: process.env.REACT_APP_APP_4, gtag: process.env.REACT_APP_GTAG_4});
              break;
          case process.env.REACT_APP_DOMAIN_5:
              callback({env: process.env.REACT_APP_APP_5, gtag: process.env.REACT_APP_GTAG_5});
              break;
          case process.env.REACT_APP_DOMAIN_6:
              callback({env: process.env.REACT_APP_APP_6, gtag: process.env.REACT_APP_GTAG_6});
              break;
          case process.env.REACT_APP_DOMAIN_7:
              callback({env: process.env.REACT_APP_APP_7, gtag: process.env.REACT_APP_GTAG_7});
              break;
          case process.env.REACT_APP_DOMAIN_8:
              callback({env: process.env.REACT_APP_APP_8, gtag: process.env.REACT_APP_GTAG_8});
              break;
          case process.env.REACT_APP_DOMAIN_9:
              callback({env: process.env.REACT_APP_APP_9, gtag: process.env.REACT_APP_GTAG_9});
              break;
          default:
              callback({env: process.env.REACT_APP_APP, gtag: process.env.REACT_APP_GTAG});    // site Recrutement
              break;
      }*/
}

const App = () => {
    // Global apis
    let dispatch = useDispatch();
    let globalStore = useSelector(state => state.global);

    let [siteClass, setSiteClass] = useState('');

    let history = useHistory();

    const nPostingTargetId = urlSearchParams('nPostingTargetId')

    const [urlToRedirect, urlToRedirectIsLoaded] = useApi({ name: 'posts', params: `&target_id=${nPostingTargetId}` })

    useEffect(() => {
        if (urlToRedirectIsLoaded && urlToRedirect.url) {
            return history.replace(urlToRedirect.url);
        }
    }, [urlToRedirectIsLoaded, history, urlToRedirect])

    if (globalStore.site === "") {
        if (window.location.hostname !== "localhost") {
            processRelatedEnvLogic(window.location.origin, ({ env }) => {
                if (env === process.env.REACT_APP_DOMAIN_2) {
                    setSiteClass('intern_website');
                } else {
                    setSiteClass('');
                }
                dispatch(updateGlobalStoreSite(env));
            });
        } else {
            dispatch(updateGlobalStoreSite(process.env.REACT_APP_APP));
            setSiteClass(''); // penser à ajouter la class "intern_website" ici pour voir les couleurs du site Interne
            // dispatch(updateGlobalStoreSite(process.env.REACT_APP_APP_2)); // site Mobilité
            // setSiteClass('intern_website');
        }
    }

    // API dynamiques ou via des JSON
    let [dataGlobalAPI, dataGlobalIsLoaded] = useApi({ name: 'global' });
    let [dataRoutesAPI, routesIsLoaded] = useApi({ name: 'routes' });
    let [dataElevatorAPI, elevatorIsLoaded] = useApi({ name: 'elevator/choices' });
    //  console.log({globalStore})
    let { routes, isLoaded, global: { colors } } = globalStore; // eslint-disable-line

    const customPrimaryColor = colors?.primary;
    const haveCustomPrimaryColor = !!customPrimaryColor;
    const customSecondaryColor = colors?.secondary;
    const haveCustomSecondaryColor = !!customSecondaryColor;

    // Function to convert hex to RGBA
    function hexToRgb(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const colors = [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ];
        return result ? colors.join(',') : null;
    }

    let page = useSelector(state => state.page).page;
    let cookiesFilters = useSelector(state => state.page).filters;

    let { m767, m1024, m1199 } = useSelector(state => state.breakpoint);

    const changeFavicon = () => {
        let favicon = dataGlobalAPI?.header?.favicon;
        if (favicon) {
            document.querySelector("head").querySelector("[rel='icon']").href = favicon;
        } else {
            document.querySelector("head").querySelector("[rel='icon']").href = "/favicon.ico";
        }
    }

    const loadMoreStyle = () => {
        var head = document.querySelector("head");
        if (dataGlobalAPI.styles && !head.querySelector(".moreStyle")) {
            dataGlobalAPI.styles.forEach(style => {
                var link = document.createElement('link');
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.className = "moreStyle"
                link.href = style;
                head.appendChild(link);
            })
        }
    }

    // RESTRICTED OR NOT
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const external = params.get('external');
        if (external && external === 'true') {
            dispatch(updateGlobalisRestricted(true));
        } else {
            dispatch(updateGlobalisRestricted(false));
        }
    }, [page]); // eslint-disable-line

    // INIT USER
    useEffect(() => {
        dispatch(updateGlobalUser(getCookie('userID')));
    }, [dispatch]);

    // // INIT FILTERS
    // useEffect(() => {
    //     dispatch(updateFilters(getCookie('filters')));
    // }, [dispatch]);


    // STORE ADD GLOBAL API
    useEffect(() => {
        dispatch(updateGlobalStoreGlobal(dataGlobalAPI));
        //console.log({dataGlobalAPI})
        if (dataGlobalAPI) {
            changeFavicon();
        }
        loadMoreStyle(dataGlobalAPI)
    }, [dispatch, dataGlobalAPI]);
    // }, [dataGlobalAPI])

    // STORE ADD ROUTES API
    useEffect(() => {
        dispatch(updateGlobalStoreRoutes(dataRoutesAPI));
    }, [dispatch, dataRoutesAPI]);

    // STORE ADD ELEVATOR API
    useEffect(() => {
        dispatch(updateGlobalStoreElevator(dataElevatorAPI));
    }, [dispatch, dataElevatorAPI]);

    // INIT ELEVATOR RESULTS REDUCER
    useEffect(() => {
        dispatch(updateElevatorResults(getCookie('elevator')));
    }, [dispatch]);

    // STORE SET IS LOADED
    useEffect(() => {
        dispatch(updateGlobalisLoaded(routesIsLoaded && dataGlobalIsLoaded));
        if (routesIsLoaded && dataGlobalIsLoaded) {
            window.prerenderReady = true;
        }
    }, [dispatch, routesIsLoaded, dataGlobalIsLoaded]);

    useEffect(() => {
        if (window.tarteaucitron) {
            /* let timer = setInterval(() => {
                 if (window.tarteaucitron.state['gtag'] === true) {
                     if (window.location.hostname !== "localhost") {
                         processRelatedEnvLogic(window.location.origin, ({env, gtag}) => ReactGA.initialize(gtag));
                     } else {
                         //noUA
                     }
                     dispatch(updateGA(true));

                     clearInterval(timer)
                 }
             }, 250);*/
            let timer2 = setInterval(() => {
                if (window.tarteaucitron.state['youtubeapi'] === true) {
                    dispatch(updateGlobalYoutube(true));
                    clearInterval(timer2);
                }
            }, 250);
            let timer3 = setInterval(() => {
                if (window.tarteaucitron.state['datadriven'] === true) {
                    dispatch(updateDataDriven(true));
                    clearInterval(timer3);
                }
            }, 250);
        }
    }, []); // eslint-disable-line


    // DISPATCH LANGUAGE FROM URL
    useEffect(() => {
        let langArray = window.location.href.split('/');
        langArray[3] === 'en' ? dispatch(updateGlobalStoreLang('en')) : dispatch(updateGlobalStoreLang('fr'));
    }, [dispatch]);

    // DISPATCH WINDOW SIZE ON RESIZE
    useEffect(() => {
        const checkDevice = () => {
            if (window.matchMedia('(max-width:767px)').matches) {
                dispatch(updateIs767(true));
            } else {
                dispatch(updateIs767(false));
            }
            if (window.matchMedia('(max-width:991px)').matches) {
                dispatch(updateIs991(true));
            } else {
                dispatch(updateIs991(false));
            }
            if (window.matchMedia('(max-width: 1024px)').matches) {
                dispatch(updateIs1024(true))
            } else {
                dispatch(updateIs1024(false))
            }
            if (window.matchMedia('(max-width:1199px)').matches) {
                dispatch(updateIs1199(true))
            } else {
                dispatch(updateIs1199(false))
            }
        };

        checkDevice();

        window.addEventListener('resize', checkDevice);

        // clean listener
        return () => {
            window.removeEventListener('resize', checkDevice);
        }
    }, [dispatch, m767, m1024, m1199]);

    return (
        <div className="bodyContainer">
            <div className={`app${haveCustomSecondaryColor ? ' customColors' : ''} ${page} ${siteClass}`}>
                {isLoaded && elevatorIsLoaded ? (
                    <ScrollToTop>
                        <Header />

                        <Switch>
                            {dataRoutesAPI && (
                                dataRoutesAPI.map((route) => {
                                    return <RouteForTemplates key={route._uid} {...route} datas={{}} />;
                                })
                            )}
                        </Switch>

                        <LoginModal customColors={haveCustomSecondaryColor} />
                        <RegisterModal customColors={haveCustomSecondaryColor} />
                        <AlertModal customColors={haveCustomSecondaryColor} />
                        <ForgotPasswordModal customColors={haveCustomSecondaryColor} />
                        <ResetPasswordModal customColors={haveCustomSecondaryColor} />
                        {/* <CVModal/>*/}

                        <Footer />

                    </ScrollToTop>
                ) : <LoaderPage />
                }
            </div>
            {isLoaded &&
                (haveCustomPrimaryColor && (
                    <style>{`
                 :root {
    --primary-color: ${customPrimaryColor};
    --secondary-color: ${customSecondaryColor ? customSecondaryColor : '#5b1d76'};
        --primary-color-rgb: ${hexToRgb(customPrimaryColor)};
    --secondary-color-rgb: ${customSecondaryColor ? hexToRgb(customSecondaryColor) : '91,29,118'};
}
                `}</style>
                ))
            }
        </div>
    );
};

export default App;
