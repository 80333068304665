import React from 'react';
import CLink from '../Components/link';
import Testimony from '../Components/testimony';

const FHomeTestimony = ({commitment}) => {
    const {quote, text, link, testimony} = commitment;

    return (
        <>
            {(quote || (testimony && Object.keys(testimony).length > 0)) &&
                <div className="home-testimony paddingcontrol">
                    <div className="container">
                        <div className="home-testimonyFlex">
                            <div className="home-testimony-content">
                                {quote && <blockquote>{quote}</blockquote>}
                                {text && <p>{text}</p>}
                                {(link && Object.keys(link).length > 0) &&
                                    <div className="home-testimony-button">
                                        <CLink {...link} className={`c-button`} />
                                    </div>
                                }
                            </div>
                            <div className="home-testimony-item">
                                {(testimony && Object.keys(testimony).length > 0) &&
                                    <Testimony {...testimony} />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    )
};

export default FHomeTestimony;