class ParamsUrl {

    static saveUrl() {

        let search = window.location.search;
        let entries = new URLSearchParams(search).entries();

        if (!this.params) {
            this.params = {
                "sType": "",
                "tax_sector": "",
                "tax_place": "",
                "tax_degree": "",
                "tax_contract": "",
                "tax_brands": "",
                "tax_country": "",
                "tax_department": "",
                "tax_city": "",
                "tax_experience": "",
                "keyword": ""
            }
        }

        for (const entry of entries) {
            if (typeof this.params[entry[0]] !== "undefined") {
                if (this.params[entry[0]] == "" || (entry[1] !== "" && entry[1] !== this.params[entry[0]])) {
                    this.params[entry[0]] = entry[1];
                }
            }
        }
    }

    static setUrl(url = "") {

        let a = document.createElement("a");
        a.href = url;

        let pathname = a.origin + a.pathname;
        let hash = a.hash;
        let search = a.search.substring(1);

        let tempParams = {};
        let entries = new URLSearchParams(search).entries();

        for (const entry of entries) {
            tempParams[entry[0]] = entry[1];
        }

        for (const param in this.params) {
            tempParams[param] = this.params[param];
        }

        let finalSearch = "";
        for (const param in tempParams) {
            if (tempParams[param] !== "") {
                finalSearch += (finalSearch === "" ? "?" : "&") + param + "=" + tempParams[param];
            }
        }

        return {
            url: pathname + finalSearch + hash,
            pathname,
            search: "?" + search,
            hash
        }
    }
}

export default ParamsUrl;