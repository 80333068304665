import React, {useEffect, useState} from "react";

// Libraries
import Axios from "axios";
import imagesLoaded from 'imagesloaded';
// Import Swiper React components
import SwiperCore, {Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Hooks
import {useSelector} from "react-redux";

// Components
import SocialList from "./social-list";

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const SocialWall = ({title}) => {

    let [dataSocial, dataSocialIsLoaded] = useState(false);
    let [dataSocialError, dataSocialHasError] = useState(false);
    let [socialPosts, setSocialPosts] = useState([]);

    let dataGlobal = useSelector(state => state.global).global;

    // multisite : get current site
    let site = useSelector(state => state.global).site;

    // DESTRUCTURING
    const {social} = dataGlobal.footer;

    useEffect(() => {
        fetchSocialPosts();
    }, []);

    const fetchSocialPosts = () => {
        let fetch = Axios.get(`${site}/wp-json/df-social-wall/v3/view-all-social-posts/`);


        fetch.then(res => {
            let {status, data} = res;

            if (data && status === 200) {
                dataSocialIsLoaded(true);
                let posts = [];
                for (const key in data) {
                    posts.push(data[key]);
                }
                setSocialPosts(posts);

            } else {
                dataSocialIsLoaded(false);
                dataSocialHasError(true);
                // Error
                console.log('Error fetching social posts');
            }
        });
    };
    return (
        <>
            {socialPosts.length > 0 &&
            <div className="c-tint" data-bg="grey">
                <div className="container">
                    <div className="c-tintFlex">
                        {title && <div className="title-50">{title}</div>}
                        {(social && Object.keys(social).length > 0) &&
                        <SocialList social={social} className="c-tint-links"/>}
                    </div>
                    <>
                        {(dataSocial && !dataSocialError) ?
                            <SocialPosts socialPosts={socialPosts}/> : fetchSocialPosts()}
                    </>
                </div>
            </div>
            }
        </>
    )
};

export default SocialWall;

const SocialPosts = ({socialPosts}) => {

    let {m1024} = useSelector(state => state.breakpoint);
    const [mobile, setMobile] = useState(m1024);

    useEffect(() => {
        if (m1024) {
            setMobile(true);
        } else {
            setMobile(false);
            setTimeout(() => {
                resizeAllGridItems()
            }, 120);
            setTimeout(() => {
                resizeAllGridItems()
            }, 1000);
        }
    }, [m1024]); // eslint-disable-line


    function resizeGridItem(item) {
        let grid = document.getElementsByClassName("grid")[0];
        // console.log(item);
        if (grid) {
            // let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
            let rowHeight = 5;
            let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
            let rowSpan = Math.ceil((item.querySelector('.grid-item-content').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
            item.style.gridRowEnd = "span " + rowSpan;
        }
    }

    function resizeAllGridItems() {
        let allItems = document.getElementsByClassName("grid-item");
        if (allItems.length) {
            for (let x = 0; x < allItems.length; x++) {
                resizeGridItem(allItems[x]);
            }
        }
    }

    function resizeInstance(instance) {
        let item = instance.elements[0];
        resizeGridItem(item);
    }

    let allItems = document.getElementsByClassName("grid-item");
    for (let x = 0; x < allItems.length; x++) {
        imagesLoaded(allItems[x], resizeInstance);
    }

    const ReduceImg = () => {
        const allImg = document.querySelectorAll(".item-img");
        allImg.forEach((img) => {
            if (!img.classList.contains('reduce')) {
                img.classList.add('reduce');
            }
        })
    };

    const IncreaseImg = () => {
        const allImg = document.querySelectorAll(".item-img");
        allImg.forEach((img) => {
            if (img.classList.contains('reduce')) {
                img.classList.remove('reduce');
            }
        })
    };

    return (
        <>
            {(socialPosts && socialPosts.length > 0) &&
            <>
                {mobile ?
                    (
                        <Swiper className="c-tint-posts"
                                spaceBetween={10}
                                speed={100}
                                navigation
                                autoHeight={true}
                                pagination={{clickable: true}}
                                slidesPerView={1}
                                grabCursor={true}
                                noSwiping={false}
                                scrollbar={{draggable: true}}
                                onSliderMove={() => ReduceImg()}
                                onTouchEnd={() => IncreaseImg()}
                                freeMode={false}
                                resistance={false}
                                freeModeMomentumRatio={1}
                                freeModeMomentumVelocityRatio={0.5}
                                freeModeMomentumBounceRatio={1}
                                breakpoints={{
                                    1025: {
                                        spaceBetween: 22,
                                        freeMode: true,
                                        slidesPerView: 'auto',
                                        autoHeight: false
                                    }
                                }}
                        >
                            {
                                socialPosts.slice(0, 5).map((post, key) => {
                                    return (
                                        <SwiperSlide key={key} className="item-img">
                                            <SocialPost {...post}/>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    ) :
                    (
                        <div className="c-tint-posts grid">
                            {socialPosts.slice(0, 10).map((post, key) => <SocialPost key={key} {...post}/>)
                            }
                        </div>
                    )
                }
            </>
            }
        </>
    )
};


const SocialPost = ({avatar, image, author, account, post_data_ago, url, network, text}) => {
    return (
        <div className="grid-item">
            <div className={`c-tint-post c-tint-post--${network} grid-item-content`}>
                {image &&
                    <a href={url} className="post__media" target="_blank" rel="noopener noreferrer">
                        <img src={image} alt={author}/>
                    </a>
                }
                <div className="post__content">
                    {text && <div className="post__content-text" dangerouslySetInnerHTML={{__html: text}}></div>}
                    <div className="post__content-footer">
                        {author ?
                           <>
                                {
                                    account ?
                                        <a href={account} className="footer__author" title={author}>
                                            {avatar &&
                                                <img src={avatar} alt={author}/>
                                            }
                                            <div className="footer__author-text">
                                                {author}
                                                <div>@{author}</div>
                                            </div>
                                        </a>
                                        :
                                        <div className="footer__author">
                                            {avatar &&
                                                <img src={avatar} alt={author}/>
                                            }
                                            <div className="footer__author-text">
                                                {author}
                                                <div>@{author}</div>
                                            </div>
                                        </div>
                                }
                           </>
                            :
                            <div></div>
                        }
                        <div className="footer__date">
                            {post_data_ago && post_data_ago}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};