import React, {useEffect, useState} from "react";

// Hooks
import {useDispatch, useSelector} from "react-redux";
import {updateModalsOpen, updateModalsLoginType} from "../Store/action";

// Libraries
import Axios from "axios";

const LikeButton = ({type, liked, postID, updateLikeComponents, rerender}) => {
    const userID = useSelector(state => state.global).userId;

    // multisite : get current site
    let site = useSelector(state => state.global).site;

    const siteType = site.indexOf('recrutement') !== -1 ? 'recrutement' : 'mobilite';
    const modalsOpen = useSelector(state => state.modals).openModal;

    let dispatch = useDispatch();

    const [likedState, setLikedState] = useState(liked);

    useEffect(() => {
        fetchLike();
    }, [userID, likedState, rerender]); // eslint-disable-line


    const fetchLike = () => {
        // Call API
        // to update liked state
        if (userID) {
            let fetch = Axios.get(`${site}/wp-json/dfcustom/v1/liked/?uid=${userID}&post_id=${postID}&post_id=${postID}&site=${siteType}`);

            fetch.then(res => {
                let {data} = res;
                setLikedState(data.liked);
            })
        }
    };

    const handleClick = () => {
        if (userID) {
            dispatch(updateModalsOpen({...modalsOpen, 'login': false}));

            // Call API
            // to update liked state
            let fetch = Axios.get(`${site}/wp-json/dfcustom/v1/liked/toggle/?uid=${userID}&post_id=${postID}&site=${siteType}`);

            fetch.then(res => {
                let {data} = res;
                setLikedState(data.liked);
                updateLikeComponents && updateLikeComponents();
            })

        } else {
            dispatch(updateModalsOpen({...modalsOpen, 'login': true}));
            dispatch(updateModalsLoginType('like'));
        }
    };

    return (
        <button type="button"
                className={`${type === 'sticky' ? 'c-offer-sticky-fav' : 'c-offer-fav'} ${likedState ? 'active' : ''}`}
                onClick={() => handleClick()}><i className="icon-heart"></i></button>
    )
};

export default LikeButton;