import React from 'react';
import News from '../Components/news';

const FHomeNews = (news) => {

    return (
        <News {...news}/>
    )
};

export default FHomeNews;