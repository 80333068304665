import React from "react";

// Hooks
import {useDispatch, useSelector} from "react-redux";
import {updateModalsOpen, updateModalsLoginType} from "../Store/action";

const AlertPush = ({title, link, buttonOnly, withIcon = true }) => {
    const userID = useSelector(state => state.global).userId;
    const modalsOpen = useSelector(state => state.modals).openModal;

    let dispatch = useDispatch();

    const handleClick = () => {
        if (userID) {
            dispatch(updateModalsOpen({...modalsOpen, 'login': false, 'alert': true}));
        } else {
            dispatch(updateModalsOpen({...modalsOpen, 'login': true, 'alert': false}));
            dispatch(updateModalsLoginType('alert'));
        }
    };

    return (
        <>
            {buttonOnly && buttonOnly === true ?
                <>
                    {link.title &&
                        <button type="button" className="c-button c-button--medium" onClick={() => handleClick()}>
                            {withIcon && <i className="icon-alert"></i>}
                                {link.title}
                        </button>
                    }
                </>
                :
                <div className="c-push c-push--pink">
                    {title && <div className="c-push-title">{title}</div>}
                    {link.title &&
                        <a type="button" className="c-button c-button--medium" target={link.target} href={link.url} onClick={!link.url ? () => handleClick() : undefined}>
                            {withIcon && <i className="icon-alert"></i>}
                            {link.title}
                        </a>
                    }
                </div>
            }
        </>
    )
};

export default AlertPush;