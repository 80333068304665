import React, {useEffect, useState} from "react";

// Hooks
import {useSelector} from "react-redux";

// Libraries
import imagesLoaded from 'imagesloaded';
import useResizeAware from 'react-resize-aware';
// Import Swiper React components
import SwiperCore, {Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Components
import ElevatorPush from "./elevator-push";
import Testimony from "./testimony";
import CLink from "./link";


SwiperCore.use([Navigation, Pagination, Scrollbar]);


const TestimonyList = ({title, items, link, hasLoadmore, elevator, results}) => {
    
    return (
        <div className="c-testimonies">
            <div className="container">
                {title &&
                <h3 className="title-50 c-testimonies-title c-testimonies-title--mini">{title}</h3>
                }
                {(items && items.length > 0) &&
                <>
                    {hasLoadmore ? <TestimonyListLoadmore items={results} link={link}/> :
                        <TestimonyListDefault items={items} elevator={elevator} link={link}/>}
                </>
                }

            </div>
        </div>
    )
};

const TestimonyListDefault = ({items, elevator, link}) => {

    let {m1024} = useSelector(state => state.breakpoint);
    const [mobile, setMobile] = useState(m1024);

    useEffect(() => {
        if (m1024) {
            setMobile(true);
        } else {
            setMobile(false);
            resizeAllGridItems()
        }
    }, [m1024]); // eslint-disable-line

    function resizeGridItem(item) {
        let grid = document.getElementsByClassName("grid")[0];
        if (grid) {
            let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
            let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
            let rowSpan = Math.ceil((item.querySelector('.grid-item-content').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
            item.style.gridRowEnd = "span " + rowSpan;
        }
    }

    function resizeAllGridItems() {
        let allItems = document.getElementsByClassName("grid-item");
        if (allItems.length) {
            for (let x = 0; x < allItems.length; x++) {
                resizeGridItem(allItems[x]);
            }
        }
    }

    function resizeInstance(instance) {
        let item = instance.elements[0];
        resizeGridItem(item);
    }

    let allItems = document.getElementsByClassName("grid-item");
    for (let x = 0; x < allItems.length; x++) {
        imagesLoaded(allItems[x], resizeInstance);
    }

    const ReduceImg = () => {
        const allImg = document.querySelectorAll(".item-img");
        allImg.forEach((img) => {
            if (!img.classList.contains('reduce')) {
                img.classList.add('reduce');
            }
        })
    };

    const IncreaseImg = () => {
        const allImg = document.querySelectorAll(".item-img");
        allImg.forEach((img) => {
            if (img.classList.contains('reduce')) {
                img.classList.remove('reduce');
            }
        })
    };

    return (
        <>
            {mobile ?
                (
                    <Swiper className="c-testimonies-list"
                            spaceBetween={10}
                            speed={100}
                            navigation
                            autoHeight={true}
                            pagination={{clickable: true}}
                            slidesPerView={1}
                            grabCursor={true}
                            noSwiping={false}
                            scrollbar={{draggable: true}}
                            onSliderMove={() => ReduceImg()}
                            onTouchEnd={() => IncreaseImg()}
                            freeMode={false}
                            resistance={false}
                            freeModeMomentumRatio={1}
                            freeModeMomentumVelocityRatio={0.5}
                            freeModeMomentumBounceRatio={1}
                            breakpoints={{
                                1025: {
                                    spaceBetween: 22,
                                    freeMode: true,
                                    slidesPerView: 'auto',
                                    autoHeight: false
                                }
                            }}
                    >
                        {
                            items.map(({image, quote, author, role, link, tags}, key) => {
                                return (
                                    <SwiperSlide key={key} className="item-img">
                                        <Testimony image={image} link={link} quote={quote} author={author}
                                                   role={role}
                                                   tags={tags}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                        {(elevator && Object.keys(elevator).length > 0) &&
                        <SwiperSlide className="item-img">
                            <ElevatorPush {...elevator}/>
                        </SwiperSlide>
                        }
                    </Swiper>
                ) :
                (
                    <>
                        <div className="c-testimonies-list grid">
                            {
                                items.map(({image, quote, author, role, link, tags}, key) => {
                                    return (
                                        <div className="grid-item" key={key}>
                                            <Testimony image={image} link={link} quote={quote} author={author}
                                                       role={role}
                                                       tags={tags}/>
                                        </div>
                                    )
                                })
                            }
                            {(elevator && Object.keys(elevator).length > 0) &&
                            <div className="grid-item">
                                <ElevatorPush {...elevator}/>
                            </div>
                            }
                        </div>

                    </>
                )
            }
            {(link && Object.keys(link).length > 0) &&
            <div className="c-testimonies-button paddingcontrol">
                {link.title &&
                <CLink {...link} className={`c-button`}/>
                }
            </div>
            }
        </>
    )
};

const TestimonyListLoadmore = ({items, link}) => {
    const [resizeListener, sizes] = useResizeAware();
    const loadmoreNumber = 12;
    const [loadmore, setLoadmore] = useState(loadmoreNumber);


    useEffect(() => {
        resizeAllGridItems()
    }, [sizes.width, sizes.height, items]); // eslint-disable-line


    useEffect(() => {
        resizeAllGridItems()
    }, [loadmore]); // eslint-disable-line


    function resizeGridItem(item) {
        let grid = document.getElementsByClassName("grid")[0];
        let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
        let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
        let rowSpan = Math.ceil((item.querySelector('.grid-item-content').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
        item.style.gridRowEnd = "span " + rowSpan;
        //setResize(true);
    }

    function resizeAllGridItems() {
        let allItems = document.getElementsByClassName("grid-item");
        for (let x = 0; x < allItems.length; x++) {
            resizeGridItem(allItems[x]);
        }
    }

    function resizeInstance(instance) {
        let item = instance.elements[0];
        resizeGridItem(item);
    }

    //resizeAllGridItems();

    let allItems = document.getElementsByClassName("grid-item");
    for (let x = 0; x < allItems.length; x++) {
        imagesLoaded(allItems[x], resizeInstance);
    }

    return (
        <>
            {resizeListener}
            <div className="c-testimonies-list grid">
                {items.slice(0, loadmore).map(({image, quote, author, role, link, tags}, key) => {
                    return (
                        <div className="grid-item" key={key}>
                            <Testimony image={image} link={link} quote={quote} author={author} role={role} tags={tags}/>
                        </div>
                    )
                })}
            </div>
            {loadmore < items.length &&
            <div className="c-testimonies-button paddingcontrol">
                <button type="button" className="c-button"
                        onClick={() => setLoadmore(parseInt(loadmore + loadmoreNumber))}>{link}
                </button>
            </div>
            }
        </>
    )

};

export default TestimonyList;