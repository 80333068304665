import React from "react";

// Libraries
// Import Swiper React components
import SwiperCore, {Navigation, Pagination, Scrollbar, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Components
import CLink from "./link";


SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

const News = ({title, items, timer}) => {
    return (
        <>
            {(items && items.length > 0) &&
            <div className="c-news paddingcontrol">
                <div className="container">
                    {title && <h2 className="title-50">{title}</h2>}
                    {(items && items.length > 0) &&
                    <Swiper className="c-news-slider"
                            speed={500}
                            autoHeight={true}
                            pagination={{clickable: true}}
                            slidesPerView={1}
                            autoplay={{delay: timer, disableOnInteraction: false, reverseDirection: true}}
                    >
                        {
                            items.map((item, key) => {
                                return (
                                    <SwiperSlide key={key}>
                                        <NewsItem {...item}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    }
                </div>
            </div>
            }
        </>
    )
};

const NewsItem = ({image, title, text, link}) => {
    return (
        <div className="c-newsItem">
            <div className="c-newsItem-img">
                <img src={image.src} alt={image.alt}/>
            </div>

            {(title !== '' || text !== '' || Object.keys(link).length !== 0) && <div className="c-newsItem-content">
                {title && <h3 className="title-40">{title}</h3>}
                <div className="c-newsItem-content-right">
                    {text && <p>{text}</p>}
                    {(link && Object.keys(link).length > 0) && <CLink {...link} className={`c-button`}/>}
                </div>
            </div>}
        </div>
    )
};

export default News;