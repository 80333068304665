import React, { useState, useRef } from "react";

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import useApi from "../../Hooks/useApi";
import { updateModalsOpen } from "../../Store/action";

// Libraries
import ReCAPTCHA from "../../../node_modules/react-google-recaptcha";
import { useForm, Controller } from "react-hook-form";
import Axios from "axios";

// Components
import Modal from "./modal";

const RegisterModal = ({customColors}) => {
    let dispatch = useDispatch();

    // multisite : get current site
    let site = useSelector(state => state.global).site;

    const renderMarkup = (markup) => {
        return { __html: markup };
    };

    // Modals workflow
    const useSelectorModals = useSelector(state => state.modals);
    const modalsOpen = useSelectorModals.openModal;

    // Register modal
    const [dataFetch, isLoaded] = useApi({ name: 'user/popin/create' });

    // Recaptcha
    let lang = useSelector(state => state.global).lang;
    let captchaRef = useRef(null);

    // Register form
    const { register, handleSubmit, watch, setValue, formState, setError, errors, control } = useForm({
        defaultValues: {
            'g-recaptcha-response': null
        }
    });

    const watchPassword = watch('registerPassword');

    let [successSend, setSuccessSend] = useState(false);
    let [globalError, setGlobalError] = useState(null);


    const onSubmit = (data) => {
        setGlobalError(null);

        let formData = new FormData();

        formData.append('firstname', data.registerSurname);
        formData.append('lastname', data.registerName);
        formData.append('mail', data.registerEmail);
        formData.append('password', data.registerPassword);
        formData.append('optin', data.registerOptin);
        formData.append('g-recaptcha-response', data['g-recaptcha-response']);

        let fetch = Axios.post(`${site}/wp-json/bpce/v1/user/create/`, formData);

        fetch.then(res => {
            let { status, data } = res;

            if (data.success && status === 200 && !Object.keys(errors).length) {
                setSuccessSend(true);

                setTimeout(() => {
                    dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'register': false }));
                }, 500);

            } else if (!data.success) {
                setSuccessSend(false);

                let error = data.error;

                if (error) {
                    if (typeof error === "string") {
                        setGlobalError(error);
                    }
                    if (error.firstname) {
                        setError("registerFirstName", {
                            type: "required"
                        });
                    }
                    if (error.lastname) {
                        setError("registerName", {
                            type: "required"
                        });
                    }
                    if (error.mail) {
                        setError("registerEmail", {
                            type: "required"
                        });
                    }
                    if (error.password) {
                        setError("registerPassword", {
                            type: "required"
                        });
                    }
                    if (error.optin) {
                        setError("registerOptin", {
                            type: "required"
                        });
                    }
                    if (error['g-recaptcha-response']) {
                        setError("g-recaptcha-response", {
                            type: "required"
                        });
                    }
                    setTimeout(() => {
                        captchaRef.current.reset();

                        setError("g-recaptcha-response", {
                            type: "required"
                        });
                    }, 50);
                }
            }
        })
    };

    const renderModal = () => {
        
        const { title, fields, recaptcha, optin, submit, success, text_explanations, passwordValidation } = dataFetch;

        return (
            <Modal modalName="register" customColors={customColors}>
                {successSend ?
                    <div className="success">
                        <div className="title-30"><i className="icon icon-check"></i>{success}</div>
                    </div>
                    :
                    <>

                        {title && <h3 className="title-30">{title}</h3>}

                        {globalError && <div className="error error--big">{globalError}</div>}

                        {(fields && Object.keys(fields).length > 0) &&
                            <form className="c-form" onSubmit={handleSubmit(onSubmit)} autoComplete="noFillForm">
                                <div className="c-form-input" data-error={errors.registerSurname ? 'true' : 'false'}>
                                    <label htmlFor="registerSurname" className="hidden">{fields.firstname.label}</label>
                                    <input type="text" placeholder={fields.firstname.label} name="registerSurname"
                                        id="registerSurname"
                                        ref={register({
                                            required: true,
                                            maxLength: 20,
                                            pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i
                                        })}
                                        autoComplete="noFillFirstName"
                                    />
                                </div>

                                {errors.registerSurname ?.type === "required" &&
                                    <div className="error">{fields.firstname.errors.empty}</div>}
                                {errors.registerSurname ?.type === "pattern" &&
                                    <div className="error">{fields.firstname.errors.bad}</div>}

                                <div className="c-form-input" data-error={errors.registerName ? 'true' : 'false'}>
                                    <label htmlFor="registerName" className="hidden">{fields.lastname.label}</label>
                                    <input type="text" placeholder={fields.lastname.label} name="registerName"
                                        id="registerName"
                                        ref={register({ required: true, pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i })}
                                        autoComplete="noFillName"
                                    />
                                </div>

                                {errors.registerName ?.type === "required" &&
                                    <div className="error">{fields.lastname.errors.empty}</div>}
                                {errors.registerName ?.type === "pattern" &&
                                    <div className="error">{fields.lastname.errors.bad}</div>}

                                <div className="c-form-input" data-error={errors.registerEmail ? 'true' : 'false'}>
                                    <label htmlFor="registerEmail" className="hidden">{fields.mail.label}</label>
                                    <input type="email" placeholder={fields.mail.label} name="registerEmail"
                                        id="registerEmail"
                                        ref={register({ required: true, pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g })}
                                        autoComplete="noFillEmail"
                                    />
                                </div>

                                {errors.registerEmail ?.type === "required" &&
                                    <div className="error">{fields.mail.errors.empty}</div>}
                                {errors.registerEmail ?.type === "pattern" &&
                                    <div className="error">{fields.mail.errors.bad}</div>}

                                <div className="c-form-input" data-error={errors.registerPassword ? 'true' : 'false'}>
                                    <label htmlFor="registerPassword" className="hidden">{fields.password.label}</label>
                                    <input type="password" placeholder={fields.password.label} name="registerPassword"
                                        id="registerPassword"
                                        ref={register({ required: true, minLength: 8 })} autoComplete="noFillPassword" />
                                </div>

                                {errors.registerPassword ?.type === "required" &&
                                    <div className="error">{fields.password.errors.empty}</div>}
                                <div className="register-password-explanations__container">
                                    <p>{text_explanations}</p>
                                    <ul>
                                    <li style={{ color: /.{9,}/.test(watchPassword) && watchPassword ? '#6abf79' : formState.isSubmitted ? '#e76b72' : '#707070' }}>{passwordValidation.size}</li>
                                        <li style={{ color: /[0-9]/.test(watchPassword) && watchPassword ? '#6abf79' : formState.isSubmitted ? '#e76b72' : '#707070' }}>{passwordValidation.number}</li>
                                        <li style={{ color: /(.*[A-Z].*)/.test(watchPassword) && watchPassword ? '#6abf79' : formState.isSubmitted ? '#e76b72' : '#707070' }}>{passwordValidation.uppercase}</li>
                                        <li style={{ color: /[!@#$%^&*(),.?":{}|<>]/.test(watchPassword) && watchPassword ? '#6abf79' : formState.isSubmitted ? '#e76b72' : '#707070' }}>{passwordValidation.special}</li>
                                    </ul>

                                </div>

                                <div className="c-form-checkbox" data-error={errors.registerOptin ? 'true' : 'false'}>
                                    <input type="checkbox" name="registerOptin" id="registerOptin"
                                        ref={register({ required: true })} />
                                    <span className="c-form-checkbox-box">&nbsp;</span>
                                    <label htmlFor="registerOptin"
                                        dangerouslySetInnerHTML={renderMarkup(optin.label)}></label>
                                </div>

                                {errors.registerOptin ?.type === "required" &&
                                    <div className="error">{optin.errors.empty}</div>}

                                {recaptcha.key &&
                                    <div className="c-form-recaptcha"
                                        data-error={errors['g-recaptcha-response'] ? 'true' : 'false'}>
                                        <Controller
                                            control={control}
                                            name="g-recaptcha-response"
                                            rules={{ required: true }}
                                            render={() => (
                                                <ReCAPTCHA
                                                    ref={captchaRef}
                                                    sitekey={recaptcha.key}
                                                    className="g-recaptcha"
                                                    name="g-recaptcha-response"
                                                    hl={lang}
                                                    data-size="compact"
                                                    onChange={val => {
                                                        setValue("g-recaptcha-response", val);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                }

                                {errors['g-recaptcha-response'] ?.type === "required" &&
                                    <div className="error">{recaptcha.errors.empty}</div>}


                                <button type="submit" className="c-button">{submit}</button>
                            </form>
                        }
                    </>
                }
            </Modal>
        )
    };

    return (
        <>
            {isLoaded ? renderModal() : 'Loading'}
        </>
    )
};

export default RegisterModal;