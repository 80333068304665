import React from "react";

// Hooks
import {useDispatch, useSelector} from "react-redux";
import {
    updateModalsOpen,
    updateModalsLoginType,
    updateModalsRedirectButton,
    updateModalsOpeningId
} from "../Store/action";
import ParamsUrl from "../Utils/paramsUrl";

// Components
import CLink from "./link";

const PostulateButton = ({
    type,
    children,
    button,
    modal,
    openingId,
    partner = ''
}) => {
    //const userID = useSelector(state => state.global).userId;
    const modalsOpen = useSelector(state => state.modals).openModal;

    button.url = ParamsUrl.setUrl(button.url).url;

    let dispatch = useDispatch();

    const handleClick = () => {
        // if (userID) {
        //     dispatch(updateModalsOpen({...modalsOpen, 'login': false}));
        // } else {
        dispatch(updateModalsOpen({...modalsOpen, 'login': true}));
        dispatch(updateModalsLoginType('postulate'));
        dispatch(updateModalsRedirectButton(button));
        dispatch(updateModalsOpeningId(openingId));
        // }
    };

    const renderButtonClass = () => {
        switch (type) {
            case 'button':
                return "c-button c-button--big";
            case 'sticky':
                return "c-offer-sticky-button c-button";
            default:
                return "c-button"
        }
    };

    return (
        <>
            {!modal ?
                <CLink className={renderButtonClass()} {...button} partner={partner}>{children}</CLink>
                :
                <button type="button" className={renderButtonClass()}
                        onClick={() => handleClick()}>{children}</button>
            }

            {/* <CLink className={renderButtonClass()} {...button}>{children}</CLink>*/}
        </>
    )
};

export default PostulateButton;