import React, {useEffect, useState} from "react";

// Libraries
import ReactPlayer from 'react-player/youtube';

// Hooks
import {useSelector, useDispatch} from 'react-redux';
import {updateGlobalStorePageBis, updateGlobalYoutube} from "../Store/action";
import {updateGlobalStorePage} from '../Store/action';
import useApi from '../Hooks/useApi';

// Components
import {LoaderPage} from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import Quote from "../Components/quote";
import Share from "../Components/share";
import Keynumbers from "../Components/keynumbers";
import TestimonyList from "../Components/testimony-list";
import CLink from "../Components/link";

const TemplateTestimonyDetails = ({_uid}) => {

    const [dataFetch, isLoaded] = useApi({name: 'posts', _uid: _uid});

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-testimony-details'));
        dispatch(updateGlobalStorePageBis(''));
    }, [dispatch]); // isLoaded

    const [videoHeaderOverlay, setVideoHeaderOverlay] = useState(true);
    const [youtubeRGPD, setYoutubeRGPD] = useState(window.tarteaucitron.state.youtubeapi);

    const videoSettings = {
        youtube: {
            playerVars: {showinfo: 0}
        }
    };

    let youtubeState = useSelector(state => state.global).youtube;

    useEffect(() => {
        dispatch(updateGlobalYoutube(youtubeRGPD));
    }, [dispatch, youtubeRGPD]);


    let {m1024} = useSelector(state => state.breakpoint);
    const [mobile, setMobile] = useState(m1024);

    useEffect(() => {
        if (m1024) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [m1024]);

    const renderMarkup = (markup) => {
        return {__html: markup};
    };

    function renderPage() {
        const {metas, content: {top: {video, firstname, role, quote, sharer}, main: {description}, aside: {know_more, glassdoor}, bottom: {keyfigure, testimonies, elevator}}} = dataFetch;

        return (
            <>
                <Metatags {...metas} />

                <main className="main">

                    {(video && video.image) ? (
                        <div className="main__header" data-color="blue">
                            <div className="main__header-media c-video"
                                 onClick={() => youtubeState ? setVideoHeaderOverlay(false) : ''}>
                                {video.id &&
                                <>
                                    {youtubeState ?
                                        <ReactPlayer className="react-player c-video-iframe"
                                                     config={videoSettings}
                                                     controls={true}
                                                  //   light={true}
                                                     playing={true}
                                                     url={`https://www.youtube.com/watch?v=${video.id}`}
                                                     //poster={video.image.src ? video.image.src : ''}
                                                     light={video.image.src ? video.image.src : true}
                                                     width='100%' height='100%'
                                                     playIcon={<div className="container">
                                                         <button className="c-video-button" type="button"><i
                                                             className="icon-play"></i></button>
                                                     </div>}
                                        />
                                        :
                                        <div className="video_cookie c-video-iframe"
                                             style={{backgroundImage: `url(${video.image.src ? video.image.src : ''})`}}
                                             onClick={() => setYoutubeRGPD(true)}>
                                            <div
                                                dangerouslySetInnerHTML={renderMarkup(window.tarteaucitron.engage("youtubeapi"))}></div>
                                        </div>
                                    }
                                </>
                                }
                                <div>
                                    {videoHeaderOverlay &&
                                    <>
                                        {/*<img src={video.image.src} alt={video.image.alt} className="c-video-iframe"/>*/}
                                        <div className="media__caption" style={{pointerEvents:"none"}}>
                                            <div className="container">
                                                <div className="media__captionFlex">
                                                    <button className="c-video-button" type="button" style={{opacity:0}}><i
                                                        className="icon-play" ></i></button>
                                                    <div className="media__caption-title">
                                                        <h1 className="title-50 title-50--alt">{firstname}<span>{role}</span>
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    }
                                </div>

                            </div>
                            <div className="main__header-intro">
                                {(mobile && (sharer && Object.keys(sharer).length > 0)) &&
                                <div className="container c-share-ctn">
                                    <Share {...sharer}/>
                                </div>
                                }

                                <div className="container container--mini">
                                    <Quote text={quote}/>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="main__header" data-color="blue">
                            <div className="main__header-intro">
                                <div className="container">
                                    <div className="main__header-introFlexText">
                                        <h1 className="title-50 title-50--alt">{firstname}<span>{role}</span></h1>
                                    </div>
                                </div>
                                <div className="container container--mini">
                                    <Quote text={quote}/>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="main__content" data-bg="grey" data-color="blue">
                        <div className="container">
                            <div className="main__contentFlex">
                                <div className="main__content-primary"
                                     dangerouslySetInnerHTML={renderMarkup(description)}></div>

                                <div className="main__content-aside">
                                    {(!mobile && (sharer && Object.keys(sharer).length > 0)) &&
                                    <Share {...sharer}/>
                                    }

                                    {(know_more && Object.keys(know_more).length > 0) &&
                                    <div className="c-push c-push--darkPurple">
                                        <div className="c-push-title"
                                             dangerouslySetInnerHTML={renderMarkup(know_more.title)}></div>
                                        <CLink {...know_more.link} className={`c-button c-button--big`}/>
                                    </div>
                                    }

                                    {(glassdoor && Object.keys(glassdoor).length > 0) &&
                                    <div className="c-push c-push--appleGreen">
                                        {glassdoor.title && <div className="c-push-title"
                                                                 dangerouslySetInnerHTML={renderMarkup(glassdoor.title)}></div>}
                                        {glassdoor.text && <div className="c-push-glassdoor">{glassdoor.text}</div>}
                                        {(glassdoor.link && Object.keys(glassdoor.link).length > 0) &&
                                        <CLink className="c-button c-button--big" {...glassdoor.link}/>}
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {(keyfigure && Object.keys(keyfigure).length > 0) &&
                    <Keynumbers {...keyfigure}/>
                    }

                    {(testimonies && testimonies.items && testimonies.items.length > 0) &&
                    <>
                        {(elevator && Object.keys(elevator).length > 0) ?
                            (
                                <TestimonyList {...testimonies} elevator={elevator}/>
                            ) : (
                                <TestimonyList {...testimonies}/>
                            )
                        }
                    </>
                    }

                </main>
            </>
        )
    }

    return isLoaded ? renderPage() : <LoaderPage/>
};

export default TemplateTestimonyDetails;