import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Video from '../Components/video';

const FHomeCards = ({imgFilter, items}) => {

    function hex_is_light(color) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
    }

    const [darkColor, setDarkColor] = useState(imgFilter ? hex_is_light(imgFilter) : false);

    return (
        <>
            {(items && items.length > 0) &&
            <div className="c-cards margincontrol">
                <div className="container">
                    <div className={`c-cards__list${imgFilter ? ' c-cards__list--filter' : ''}`} data-dark={darkColor}>
                        {
                            items.map(({type, title, image, link, subtitle, youtube_id}, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        {type === 'video' ?
                                            <div className="c-cards__item">
                                                <Video youtubeID={youtube_id} image={image} title={title}
                                                       imgFilter={imgFilter}/>
                                            </div>
                                            :
                                            <React.Fragment>
                                                {link && link.internal ?
                                                    <Link to={link.url} title={link.title} target={link.target}
                                                          className="c-cards__item"
                                                          style={{backgroundImage: `url(${image.src ? image.src : ''})`}}>
                                                        {imgFilter && <div className="c-cards__item-filter"
                                                                           style={{backgroundColor: imgFilter}}/>}
                                                        {title && <h3 className="title-40">{title}</h3>}
                                                        {subtitle && <p>{subtitle}</p>}
                                                    </Link>
                                                    :
                                                    <a href={link.url} title={link.title} target={link.target}
                                                       className="c-cards__item"
                                                       style={{backgroundImage: `url(${image.src ? image.src : ''})`}}>
                                                        {imgFilter && <div className="c-cards__item-filter"
                                                                           style={{backgroundColor: imgFilter}}/>}
                                                        {title && <h3 className="title-40">{title}</h3>}
                                                        {subtitle && <p>{subtitle}</p>}
                                                    </a>
                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            }
        </>
    )
};

export default FHomeCards;