import React from 'react'

// Hooks
import { useSelector } from "react-redux";

// Components
import CLink from "../../Components/link";
import SocialList from "../../Components/social-list";

const Footer = () => {
    const restricted = useSelector(state => state.global).isRestricted;

    let dataGlobal = useSelector(state => state.global).global;

    // DESTRUCTURING
    const { baseline, companies } = dataGlobal.pre_footer;
    const { footer: { menu, social, copyright, cookies, logo1, logo2 }, colors } = dataGlobal;

    const customPrimaryColor = colors ?.primary;
    const haveCustomPrimaryColor = !!customPrimaryColor;

    const customBgStyle = haveCustomPrimaryColor ? { backgroundColor: customPrimaryColor } : {};

    return (
        <>
            <footer className="footer">
                {(baseline || companies) &&
                    <div className="footerTop">
                        <div className="container container--menu">
                            {(baseline && Object.keys(baseline).length > 0) &&
                                <p className="footer__intro"><span dangerouslySetInnerHTML={{ __html: baseline.strong }}></span> <span
                                    dangerouslySetInnerHTML={{ __html: baseline.text }}></span></p>}
                            {(companies && companies.length > 0) && <Companies companies={companies} restricted={restricted} />}
                        </div>
                    </div>
                }
                <div className="footerBottom" style={customBgStyle}>
                    {logo1 &&
                        <CLink className="logo__footer" {...logo1.link}>
                            <img src={logo1.image.src}
                                alt={logo1.image.alt} />
                        </CLink>
                    }
                    <div className="container container--menu">
                        <div className="footerBottomFlex">
                            {!restricted &&
                                <>
                                    {(menu && menu.length > 0) && <FooterMenu menu={menu} cookies={cookies} />}
                                    {(social && Object.keys(social).length > 0) &&
                                        <SocialList social={social} className="footer__social" />}
                                </>
                            }
                            {copyright &&
                                <div className="footer__copyright" dangerouslySetInnerHTML={{ __html: copyright }}></div>}
                        </div>
                    </div>
                    {logo2 &&
                        <CLink className="logo__footer" {...logo2.link}>
                            <img src={logo2.image.src}
                                alt={logo2.image.alt} />
                        </CLink>
                    }
                </div>
            </footer>
            {haveCustomPrimaryColor ? (
                <style>{`
                    .footer i {
                        color: ${customPrimaryColor};
                    }
                `}</style>
            ) : null}
        </>
    )
};


const Companies = ({ companies, restricted }) => {
    return (
        <ul className="footer__logos">
            {
                companies.map(({ link, logo }, key) => {
                    if (restricted) {
                        return (
                            <li key={key}>
                                <div>
                                    <img src={logo.image.src}
                                        alt={logo.image.alt} />
                                </div>
                            </li>
                        )
                    } else {
                        return (
                            <li key={key}>
                                <CLink {...link}>
                                    <img src={logo.image.src}
                                        alt={logo.image.alt} />
                                </CLink>
                            </li>
                        )
                    }
                })
            }
        </ul>
    )
};


const FooterMenu = ({ menu, cookies }) => {
    return (
        <ul className="footer__menu">
            {
                menu.map(({ text, link }, key) => {
                    return (
                        <li key={key}>
                            <CLink {...link}>{text}</CLink>
                        </li>
                    )
                })
            }
            {window.tarteaucitron &&
                <li>
                    <button type="button"
                        onClick={() => window.tarteaucitron.userInterface.openPanel()}>{cookies}</button>
                </li>
            }
        </ul>
    )
};


export default Footer;