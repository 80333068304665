import React, {useEffect, useState} from "react";

// Hooks
import {useDispatch, useSelector} from 'react-redux';
import {updateGlobalStorePage, updateGlobalStorePageBis} from '../Store/action';
import useApi from '../Hooks/useApi';

// Libraries
// Import Swiper React components
import SwiperCore, {Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import {LoaderPage} from '../Components/loader';
import ComponentByJSON from '../Layout/routes/component-from-json';
import {Caask} from "../Components/caask";
import MainHeader from "../Layout/main/header";


SwiperCore.use([Navigation, Pagination, Scrollbar]);

const TemplateHomeFlexible = ({_uid}) => {
    let dispatch = useDispatch();
    const lang = useSelector(state => state.global).lang;

    const cookiesFilters = useSelector(state => state.global).filters;

    const [api, setApi] = useState('');

    const globalElevatorResults = useSelector(state => state.global).elevatorResults;
    let formData = new FormData();
    useEffect(() => {
        if (globalElevatorResults && Object.keys(globalElevatorResults).length > 0) {
            formData.append('lang', lang);
            formData.append('firstname', globalElevatorResults.firstname);
            formData.append('q1', globalElevatorResults.q1);
            formData.append('q2', globalElevatorResults.q2);
            formData.append('q3', globalElevatorResults.q3);
            formData.append('q4', globalElevatorResults.q4);
            formData.append('q5', globalElevatorResults.q5);

            setApi({
                name: 'elevator/personalize',
                method: 'post',
                data: formData
            });
        } else {
            setApi({name: 'pages', _uid: _uid});
        }
    }, [dispatch, globalElevatorResults]); // eslint-disable-line


    const [dataFetch, isLoaded] = useApi(api);

    useEffect(() => {
        dispatch(updateGlobalStorePage('t-home'));
        dispatch(updateGlobalStorePageBis('flexible'));
    }, [dispatch]); // isLoaded


    function renderPage() {
        const {metas, content: {visuel, title, introduction, sharer, flexibles, no_margin = true}} = dataFetch;


        return (
            <>
                <Metatags {...metas} />
                <Caask/>
                <main className={`main main__content ${no_margin ? "main__nomargin" : ""}`}>
                    <MainHeader visuel={visuel} title={title} introduction={introduction} sharer={sharer}/>
                
                    {flexibles && flexibles.map((block, index) => ComponentByJSON(block, index))}
                </main>
            </>
        )
    }


    return isLoaded ? renderPage() :
        <LoaderPage/>
};

export default TemplateHomeFlexible;