import React from "react";
import {Link} from "react-router-dom";

const SearchItem = ({url, title, type}) => {

    return (
        <Link to={url} className="search__item">
            <span className="search__item-type">{type}</span>
            <span className="search__item-wrapper">
                <span className="search__item-title" dangerouslySetInnerHTML={{__html: title}}></span>
                <i className="search__item-icon icon icon-arrow-right"></i>
            </span>
        </Link>
    )

};

export default SearchItem;