import React from 'react';

const FImgFull = ({ image }) => {

    return (
        <div className="f-imagefull margincontrol">
            <img src={image.src} alt={image.alt} />
        </div>
    )
};

export default FImgFull;