import {IS_767, IS_991, IS_1024, IS_1199} from "../action";

/**
 * REDUCER DE LA CONFIGURATION DES BREAKPOINTS
 * @param {*} state
 * @param {*} action
 */

const initialState = {
    m767: window.matchMedia('(max-width:767px)').matches ? true : false,
    m991: window.matchMedia('(max-width:991px)').matches ? true : false,
    m1024: window.matchMedia('(max-width:1024px)').matches ? true : false,
    m1199: window.matchMedia('(max-width:1199px)').matches ? true : false,
};

export function breakpointReducer(state = initialState, action) {
    switch (action.type) {
        case IS_767:
            return {...state, m767: action.value};
        case IS_991 :
            return {...state, m991: action.value};
        case IS_1024 :
            return {...state, m1024: action.value};
        case IS_1199 :
            return {...state, m1199: action.value};
        default:
            return state;
    }
}