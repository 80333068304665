import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import CLink from "./link";

const ElevatorPush = ({image, title, text, link, customPrimaryColor}) => {
    const globalElevatorResults = useSelector(state => state.global).elevatorResults;
    const [showed, isShowed] = useState(true);
    useEffect(() => {
        if (globalElevatorResults && Object.keys(globalElevatorResults).length > 0) {
            isShowed(false);
        } else {
            isShowed(true);
        }
    }, [globalElevatorResults]);

    const customBgStyles = !!customPrimaryColor ?  { backgroundColor: customPrimaryColor } : {};
    const customButtonStyles = !!customPrimaryColor ?  { color: customPrimaryColor } : {};

    return (
        <>
            {showed &&
            <div className="c-push c-push--elevator grid-item-content" style={customBgStyles}>
                {title && <div className="c-push-title">{title}</div>}
                {text && <p>{text}</p>}
                {link.title && link.url &&
                    <CLink {...link} className="c-button c-button--big" style={customButtonStyles}/>
                }
            </div>
            }
        </>
    )
};

export default ElevatorPush;