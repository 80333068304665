import React from 'react';

const FImg = ({index, image}) => {

    return (
        <div className="flexible f-image margincontrol" data-bg={(index % 2 === 0) ? 'grey' : 'white'}>
            <div className="container container--mini">
                <img src={image.src} alt={image.alt}/>
            </div>
        </div>
    )
};

export default FImg;