import React, { useEffect, useState } from "react";

// Hooks
import useApi from "../../Hooks/useApi";
import { useSelector, useDispatch } from 'react-redux';


import { useForm } from "react-hook-form";
import Axios from "axios";
// Components
import Modal from "./modal";

// Login form
// const { register, handleSubmit, errors } = useForm();



const ForgotPasswordModal = ({customColors}) => {

    const { register, handleSubmit, errors } = useForm();
    const [dataFetch, isLoaded] = useApi({ name: 'user/popin/password/forgot' });
    let site = useSelector(state => state.global).site;
    const modalsOpen = useSelector(state => state.modals).openModal
    const [isValidForm, setIsValidForm] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(null);

    const resetModal = () => {
        setIsValidForm(false);
        setSubmitMessage(null);
    }

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append('mail', data.mail);
        let fetch = Axios.post(`${site}/wp-json/bpce/v1/user/password/forgot/`, formData);
        fetch.then(res => {
            let { status, data } = res;
            setSubmitMessage(data.message)
            if (data.success && status === 200 && !Object.keys(errors).length) {
                setIsValidForm(true)
            } else if (!data.success) {
                setIsValidForm(false)
            }
        })
    };


    const renderModal = () => {
        const { title, text_intro, fields, submit, success } = dataFetch;
        return (
            <Modal modalName="forgotPassword" customColors={customColors}>
                {title && <h3 className="title-30">{title}</h3>}

                {
                    !isValidForm && (
                        <>
                            {text_intro && <p>{text_intro}</p>}

                            <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="c-form-input" data-error={errors.mail ? 'true' : 'false'}>
                                    <label htmlFor="mail" className="hidden">{fields.mail.label}</label>
                                    <input
                                        type="text"
                                        placeholder={fields.mail.label}
                                        name="mail"
                                        id="mail"
                                        onChange={() => { setSubmitMessage(null) }}
                                        ref={register({
                                            required: true,
                                            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g

                                        })}
                                        autoComplete="noFillEmail" />
                                </div>
                                {errors.mail ?.type === "required" &&
                                    <div className="error">{fields.mail.errors.empty}</div>}
                                {errors.mail ?.type === "pattern" &&
                                    <div className="error">{fields.mail.errors.bad}</div>}
                                {submitMessage && <div className="error">{submitMessage}</div>}
                                <button type="submit" className="c-button">{submit}</button>

                            </form>
                        </>
                    )
                }

                {
                    isValidForm && (
                        <>
                            <p>{success}</p>
                        </>
                    )
                }

            </Modal>
        )
    }


    useEffect(() => {
        if (!modalsOpen.forgotPassword) {
            resetModal();
        }
    }, [modalsOpen.forgotPassword])

    return (
        <>
            {isLoaded ? renderModal() : 'Loading'}
        </>
    )
};

export default ForgotPasswordModal;