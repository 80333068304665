import React, {Component} from 'react';
import "./elevator-graph.scss"
import ReactDOM from 'react-dom';

class ElevatorGraph extends Component {

    isReading = false;
    ismounted = true;
    NB_VALUE = 1024;
    FRACTION = 1 / 16;
    NB_GRAPH = 3;


    state = {
        isAudioReading: false
    };

    initAudioContext() {
        const AudioContext = window.AudioContext // Default
            || window.webkitAudioContext // Safari and old versions of Chrome
            || false;
        if (AudioContext) {
            this.audioContext = new AudioContext();
            this.analyseur = this.audioContext.createAnalyser();
            this.analyseur.fftSize = this.NB_VALUE;
            this.tailleMemoireTampon = this.analyseur.frequencyBinCount;
            this.tableauDonnees = new Uint8Array(this.tailleMemoireTampon);
            this.musicready = true;
        }
    }

    playAudio(audioURL) {
        if (audioURL && this.lastAudioURL !== audioURL) {
            this.lastAudioURL = audioURL;
            if (!this.musicready) {
                this.initAudioContext();
            }
            if (this.musicready) {
                fetch(audioURL)
                    .then(response => response.arrayBuffer())
                    .then(arrayBuffer => this.audioContext.decodeAudioData(arrayBuffer,
                        audioBuffer => {
                            this.source = this.audioContext.createBufferSource();
                            this.source.buffer = audioBuffer;
                            this.gainNode = this.audioContext.createGain();
                            // this.source.connect(this.audioContext.destination);
                            this.source.connect(this.analyseur);
                            this.source.connect(this.gainNode);
                            this.gainNode.connect(this.audioContext.destination);

                            this.setState({isAudioReading: true});


                            this.source.onended = () => {
                                // console.log('sourceended');
                                this.isReading = false;
                                // this.setState({isReading: false});
                                this.setState({isAudioReading: false});
                                this.props.onSoundEnded();
                            };

                            this.source.start();
                            this.isReading = true;

                            // console.log('2 - ' + this.props.sound);
                            if (this.props.sound) {
                                this.unmuteAudio();
                            } else {
                                this.muteAudio();
                            }


                            // console.log('reading: ' + this.state.isReading);

                        },
                        e => {
                            console.log('audioError');
                        }));
            }
        }
    }

    stopAudio() {
        try {
            // if (this.isReading) {
            //     this.source.stop();
            //     this.isReading = false;
            //
            // }
            this.source.stop();
            // setTimeout(() => {
            //
            // }, 250);
        }
        catch {

        }
        this.playAudio(this.props.nextSound);
    }

    muteAudio() {
        try {
            // if (this.isReading) {
            this.gainNode.gain.value = 0;
            // }
        }
        catch {

        }
    }

    unmuteAudio() {
        try {
            // if (this.isReading) {
            this.gainNode.gain.value = 1;
            //}
        }
        catch {

        }
    }

    componentDidMount() {
        this.dom = ReactDOM.findDOMNode(this);

        this.canvas1 = this.dom.querySelector(".canvas1");
        this.context1 = this.canvas1.getContext("2d");
        this.canvas2 = this.dom.querySelector(".canvas2");
        this.context2 = this.canvas2.getContext("2d");
        this.canvas3 = this.dom.querySelector(".canvas3");
        this.context3 = this.canvas3.getContext("2d");

        this.renderGraphics();

    }

    componentWillUnmount() {
        this.ismounted = false;
        try {
            this.source.stop();
        }
        catch {

        }
    }

    render() {
        //  console.log('1 - ' + this.props.sound);

        if (this.props.sound) {
            this.unmuteAudio();
        } else {
            this.muteAudio();
        }
        if (this.props.cutSound) {
            this.stopAudio();
        } else {
            this.playAudio(this.props.nextSound);
        }
        return (
            <div className={`elevator_graph${!this.state.isAudioReading ? ' elevator_graph--hide' : ''}`}>
                <canvas className='canvas1'></canvas>
                <canvas className='canvas2'></canvas>
                <canvas className='canvas3'></canvas>
            </div>
        );
    }

    getData(data) {
        let tab_final = data.slice(0, Math.floor(data.length * this.FRACTION));
        tab_final[0] = 0;
        tab_final[tab_final.length - 1] = 0

        if (!this.tab_pondere) {
            this.tab_pondere = [];
            tab_final.forEach(item => this.tab_pondere.push(0));
        }
        this.tab_pondere.forEach((item, index) => this.tab_pondere[index] = item * 0.85 + tab_final[index] * 0.15)

        return this.tab_pondere;
    }

    renderGraphics(t = 0) {
        this.lastT = t;
        if (this.ismounted) {

            if (this.musicready) {
                //this.analyseur.getByteTimeDomainData(this.tableauDonnees);
                this.analyseur.getByteFrequencyData(this.tableauDonnees);

                let tab_final = this.getData(this.tableauDonnees);
                let maxAmplitude = Math.max(...tab_final);
                let sumAmplitude = tab_final.reduce((a, c) => a + c);
                maxAmplitude = maxAmplitude < 2 ? 0 : maxAmplitude;
                sumAmplitude = sumAmplitude < 2 ? 0 : sumAmplitude;

                let infosSize = this.canvas1.parentElement.getBoundingClientRect();
                /*    if (infosSize.height === 0 || infosSize.height === ('undefined' || null)) {
                        infosSize.height = 501;
                    }*/
                this.cWidth = this.canvas1.width = this.canvas2.width = this.canvas3.width = Math.floor(infosSize.width * 1);
                this.cHeight = this.canvas1.height = this.canvas2.height = this.canvas3.height = Math.floor(infosSize.height * 1);

                if (this.cHeight > 0) {
                    let halfHeight = Math.floor(this.cHeight * 0.5)
                    let imageData1 = new ImageData(this.cWidth, this.cHeight);
                    this.data1 = imageData1.data;
                    let imageData2 = new ImageData(this.cWidth, this.cHeight);
                    this.data2 = imageData2.data;
                    let imageData3 = new ImageData(this.cWidth, this.cHeight);
                    this.data3 = imageData3.data;

                    let totalWidth = this.cWidth * this.NB_GRAPH;

                    for (let x = 0; x < totalWidth; x++) {
                        let x2 = ((x + Math.floor(5 * Math.abs(maxAmplitude - 180) * Math.cos(t * 0.005))) + totalWidth) % totalWidth;

                        let j = x2 % this.cWidth;
                        let index = Math.floor(x2 / this.cWidth);

                        let variationAmplitude = sumAmplitude * 0.004 * Math.cos(Math.sqrt(j + 100) * 0.5 + t * 0.005);
                        let thisHalf = halfHeight + variationAmplitude;

                        let range = (tab_final.length - 1) / this.cWidth;
                        let frequence = (x / this.NB_GRAPH) * range;
                        let percent = frequence - Math.floor(frequence);
                        let currentFrequence = Math.floor(frequence);
                        let value = this.createTrigoFunction(tab_final[currentFrequence], tab_final[currentFrequence + 1], percent);
                        let {r, g, b, adaptedValue} = this.getInfos(index, value);

                        adaptedValue = adaptedValue < 2 && index === 0 ? 2.000001 : adaptedValue;

                        let minValue = thisHalf - adaptedValue;
                        let minValueRounded = Math.floor(minValue);
                        let maxValue = thisHalf + adaptedValue;
                        let maxValueRounded = Math.ceil(maxValue);

                        for (let k = minValueRounded; k <= maxValueRounded; k++) {
                            let a

                            if (k === minValueRounded) {
                                a = 255 * (Math.ceil(minValue) - minValue);
                            } else if (k === maxValueRounded) {
                                a = 255 * (maxValue - Math.floor(maxValue));
                            } else {
                                a = this.getAlphaFromRange(Math.abs(Math.min(maxValueRounded - k, k - minValueRounded)));
                            }

                            a = Math.round(a);

                            if (a > 1) {
                                this.drawPixel(this["data" + (index + 1)], k, j, {r, g, b, a}, index);
                            }
                        }
                    }

                    this.context1.putImageData(imageData1, 0, 0);
                    this.context2.putImageData(imageData2, 0, 0);
                    this.context3.putImageData(imageData3, 0, 0);
                }


            }

            requestAnimationFrame(this.renderGraphics.bind(this));

        }
    }

    getInfos(index, value) {
        value -= 1280;
        let ratio = 0.2;

        if (index === 0) {
            return {r: 255, g: 255, b: 255, adaptedValue: value * 0.75 * ratio};
        } else if (index === 1) {
            return {r: 162, g: 228, b: 154, adaptedValue: value * 2.25 * ratio};
        } else {
            return {r: 255, g: 87, b: 148, adaptedValue: value * 2.25 * ratio};
        }
    }

    createTrigoFunction(start, end, percent) {
        start = start * 10;
        end = end * 10;
        let step = Math.cos(Math.PI * percent);
        let amplitude = (start - end) * 0.5;
        let posYbase = (start + end) * 0.5;

        return posYbase + step * amplitude;
    }

    drawPixel(data, i, j, color = {r: 255, g: 255, b: 255, a: 255}, couche) {
        let index = (i * this.cWidth + j) * 4;
        //if (color.r > 1)
        data[index] = color.r;
        //if (color.g > 1)
        data[index + 1] = color.g;
        //if (color.b > 1)
        data[index + 2] = color.b;
        //if (color.a > 1)
        data[index + 3] = color.a;
    }

    getAlphaFromRange(step) {
        let size = 50;
        let x = (step / size) - 1;
        let sizemax = 1;
        let facteur = step > size ? sizemax : sizemax * (-(x * x) + 1);
        return 255 - 255 * facteur;
    }
}

export default ElevatorGraph;