import React from 'react';

const FText = ({index, text}) => {

    const renderMarkup = (markup) => {
        return {__html: markup};
    };

    return (
        <div className="flexible f-text margincontrol" data-bg={(index % 2 === 0) ? 'grey' : 'white'}>
            <div className="container container--medium" dangerouslySetInnerHTML={renderMarkup(text)}></div>
        </div>

    )
};

export default FText;