import { MODALS_OPEN, MODALS_LOGINTYPE, MODALS_REDIRECTBUTTON, MODALS_OPENINGID } from "../action";

const initialState = {
    openModal: {
        'login': false,
        'register': false,
        'alert': false,
        'forgotPassword': false,
        'resetPassword': false,
        'cv': false
    },
    loginType: null,
    redirectButton: null,
    openingId: null
};

/**
 * MODALS REDUCER
 * @param {*} state
 * @param {*} action
 */
export function modalsReducer(state = initialState, action) {
    switch (action.type) {
        case MODALS_OPEN:
            // console.log(action.value);
            return { ...state, openModal: action.value };
        case MODALS_LOGINTYPE:
            return { ...state, loginType: action.value };
        case MODALS_REDIRECTBUTTON:
            return { ...state, redirectButton: action.value };
        case MODALS_OPENINGID:
            return { ...state, openingId: action.value };
        default:
            return state
    }
}