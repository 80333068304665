import React from "react";

const Quote = ({text, name, role}) => {
    return (
        <div className="c-quote">
            <div className="c-quote-text">{text}</div>
            {name &&
            <div className="c-quote-author">
                <span><strong>{name},</strong></span>
                {role}
            </div>
            }
        </div>
    )
};

export default Quote;