import React from "react";
import CLink from "./link";

// Hooks
// import {useDispatch, useSelector} from "react-redux";
// import {updateModalsOpen, updateModalsLoginType} from "../Store/action";

const ScoringPush = ({title, text, link}) => {
    /*
     Popin CV
     const userID = useSelector(state => state.global).userId;
     const modalsOpen = useSelector(state => state.modals).openModal;

     let dispatch = useDispatch();

     const handleClick = () => {
         if (userID) {
             dispatch(updateModalsOpen({...modalsOpen, 'login': false, 'cv': true}));
         } else {
             dispatch(updateModalsOpen({...modalsOpen, 'login': true, 'cv': false}));
             dispatch(updateModalsLoginType('cv'));
         }
     };

     return (
         <div className="c-push c-push--pink">
             <div className="c-push-title c-push-title--icon"><i className="icon-scoring"></i><span>{title}</span></div>
             <button type="button" className="c-button c-button--big" onClick={() => handleClick()}>{link.title}</button>
         </div>
     )*/
    return (
        <div className="c-push c-push--pink">
            {title && <div className="c-push-title c-push-title--icon"><i className="icon-scoring"></i><span
                dangerouslySetInnerHTML={{__html: title}}></span></div>}
            {text && <p dangerouslySetInnerHTML={{__html: text}}></p>}
            <CLink {...link} className="c-button c-button--big"/>
        </div>
    )
};

export default ScoringPush;