import {saveState, loadState} from '../../Utils/loadState';
import {
    GLOBAL_UPDATE_GLOBAL,
    GLOBAL_UPDATE_ROUTES,
    GLOBAL_UPDATE_ELEVATOR,
    GLOBAL_IS_LOADED,
    GLOBAL_IS_RESTRICTED,
    GLOBAL_UPDATE_LANG,
    GLOBAL_UPDATE_SITE,
    GLOBAL_IS_YOUTUBE,
    GLOBAL_USER,
    GLOBAL_ELEVATOR_RESULTS,
    GLOBAL_ELEVATOR_SHOWED,
    // GA,
    DATA_DRIVEN, REFERRER, PREVIOUS_LOCATION, FILTERS
} from "../action";

let initialState = {
    isLoaded: false,
    isRestricted: false,
    global: {},
    routes: {},
    elevator: {},
    youtube: false,
    userId: loadState('user-id') || null,
    lang: loadState('site-lang') || 'fr',
    site: "",
    elevatorResults: loadState('elevator') || {},
    elevatorShowed: false,
    // ga: false,
    dataDriven: false,
    referrer: "",
    previousLocation: "",
    filters: loadState('filters') || {},
};

// vanillaJS
function isJSON(str) {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function globalReducer(state = initialState, action) {
    switch (action.type) {
        case GLOBAL_UPDATE_GLOBAL:
            return {...state, global: action.value};
        case GLOBAL_UPDATE_ROUTES:
            return {...state, routes: action.value};
        case GLOBAL_UPDATE_ELEVATOR:
            return {...state, elevator: action.value};
        case GLOBAL_IS_LOADED:
            return {...state, isLoaded: action.value};
        case GLOBAL_IS_RESTRICTED:
            return {...state, isRestricted: action.value};
        case GLOBAL_UPDATE_LANG:
            saveState('site-lang', action.value);
            document.querySelector('html').setAttribute('lang', action.value);
            return {...state, lang: action.value};
        case GLOBAL_UPDATE_SITE:
            return {...state, site: action.value};
        case GLOBAL_IS_YOUTUBE:
            return {...state, youtube: action.value};
        case GLOBAL_USER:
            saveState('user-id', action.value);
            return {...state, userId: action.value};
        case GLOBAL_ELEVATOR_RESULTS:
            saveState('elevator', action.value);
            return {...state, elevatorResults: isJSON(action.value) ? JSON.parse(action.value) : action.value};
        case GLOBAL_ELEVATOR_SHOWED:
            return {...state, elevatorShowed: action.value};
        // case GA:
        //     return {...state, ga: action.value};
        case DATA_DRIVEN:
            return {...state, dataDriven: action.value};
        case REFERRER:
            return {...state, referrer: action.value};
        case PREVIOUS_LOCATION:
            return {...state, previousLocation: action.value};
        case FILTERS:
            saveState('filter', action.value);
            return {...state, filters: isJSON(action.value) ? JSON.parse(action.value) : action.value};
        default:
            return state;
    }
}
