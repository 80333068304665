import React from "react";

const DEFAULT_PRIMARY_COLOR = '#9159a4';

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const StaticHeader = (props) => {
    const { content: { imageURL, title }, primaryColor, sticky } = props;

    const styles = React.useMemo(() => {
      const color = primaryColor || DEFAULT_PRIMARY_COLOR;

      return {
        backgroundImage: `linear-gradient(to bottom, ${hex2rgba(color, 0)} -6%, ${hex2rgba(color, 0.75)}), url(${imageURL})`
      };
    }, [imageURL]);


    return (
        <div className="static-header" style={styles} data-sticky={sticky}>
          <h1 className="static-header__title">
            {title}
          </h1>
        </div>
    )
};

export default StaticHeader;