import React, {useEffect} from "react";

// Hooks
import {useDispatch, useSelector} from 'react-redux';
import {updateGlobalStorePage, updateGlobalStorePageBis} from '../Store/action';
import useApi from '../Hooks/useApi';

// Components
import {LoaderPage} from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import ComponentByJSON from '../Layout/routes/component-from-json';
import MainHeader from "../Layout/main/header";
import Offers from "../Components/offers";

const TemplateContent = ({_uid}) => {
    const cookiesFilters = useSelector(state => state.global).filters;

    const [dataFetch, isLoaded] = useApi({name: 'pages', _uid: _uid});

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-article'));
        dispatch(updateGlobalStorePageBis(''));
    }, [dispatch]); // isLoaded

    function renderPage() {
        const {metas, content: {visuel, title, introduction, sharer, flexibles, jobs_offer, no_margin = true}} = dataFetch;
        return (
            <>
                <Metatags {...metas} />
                <main className="main">

                    <MainHeader visuel={visuel} title={title} introduction={introduction} sharer={sharer}/>

                    <div className={`main__content ${no_margin ? "main__nomargin" : ""}`}>
                        {flexibles && flexibles.map((block, index) => ComponentByJSON(block, index))}
                    </div>
                    
                    {(jobs_offer && Object.keys(jobs_offer).length > 0) &&
                    <Offers {...jobs_offer} path_filtered={jobs_offer.path_filtered}
                            cookies_filtered={cookiesFilters && cookiesFilters.article}
                            src="article"/>
                    }

                </main>
            </>
        )
    }

    return isLoaded ? renderPage() : <LoaderPage/>
};

export default TemplateContent;