import React, {useEffect} from "react";

// Hooks
import {useDispatch} from 'react-redux';
import {updateGlobalStorePage, updateGlobalStorePageBis} from '../Store/action';
import useApi from "../Hooks/useApi";

// Components
import {LoaderPage} from "../Components/loader";
import CLink from "../Components/link";


const Template404 = () => {

    const [dataFetch, isLoaded] = useApi({name: '404'});

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-404'));
        dispatch(updateGlobalStorePageBis(''));
    }, [dispatch]);

    function renderPage() {
        const {title, subtitle, link} = dataFetch;
        return (
            <>
                <main className="main">
                    <div className="container">
                        <h1 className="title-130">{title}</h1>
                        <p>{subtitle}</p>
                        {(link && Object.keys(link).length > 0) &&
                        <CLink {...link} className={`c-button`}/>
                        }
                    </div>
                </main>
            </>
        )
    }

    return isLoaded ? renderPage() : <LoaderPage/>
};

export default Template404;