import React, { useState } from "react";
import { useSelector } from 'react-redux';
import "./elevator.scss"
import ElevatorContent from './elevator-content';

// External lib
// import ReactGA from 'react-ga';

const Elevator = ({ data, onSubmit, name, hover, reset, isHome, ready, isReady, haveCustomPrimaryColor, styles }) => {
    let { ga } = useSelector(state => state.global);
    const textMessageFinal = data.message_resultats;
    const [messageFinal, setMessageFinal] = useState(false);

    const finishElevator = (data) => {
        setMessageFinal(true);
        onSubmit(data);
    };

    const renderElevator = () => {
        // console.log('ready: ' + ready);
        const AudioContext = window.AudioContext // Default
            || window.webkitAudioContext // Safari and old versions of Chrome
            || false;
        if (!AudioContext) {
            console.log("API AudioContext non compatible avec ce navigateur");
            return null;
        }
        return (
            <div
                className={`elevator${name || messageFinal ? ' elevator--closed' : ''}`} data-sticky-hover={hover} data-custom-color={haveCustomPrimaryColor} style={styles}>
                {isHome &&
                    <div className="elevator__moteur">

                        {
                            !name ?
                                (
                                    !ready ?
                                        <div className="elevator__ctaStart__container">
                                            <h2 className="elevator__ctaStart--title">{data.message_title}</h2>
                                            <p className="elevator__ctaStart--text">{data.message_subtitle}</p>
                                            <button className="elevator__ctaStart elevator__texte activated"
                                                onClick={() => {
                                                    isReady(true);
                                                   /* if (ga) {
                                                        ReactGA.event({
                                                            category: 'elevator_demarrer',
                                                            action: 'Click',
                                                            label: 'Commencer l\'expérience'
                                                        });
                                                    }*/
                                                }}>{data.message_start}</button>
                                        </div>
                                        :
                                        <ElevatorContent data={data} onStarted={null} ga={ga}
                                            onSubmit={data => finishElevator(data)} haveCustomPrimaryColor={haveCustomPrimaryColor} />
                                )
                                :
                                (<div className="container">
                                    <div
                                        className={`elevator__message${messageFinal ? " elevator__message--small" : ""}`}>
                                        {messageFinal ?
                                            textMessageFinal
                                            :
                                            <>
                                                {data.message_comeback} {name}<span>{data.message_comeback_subtitle}</span>
                                            </>
                                        }
                                    </div>
                                    {name &&
                                        <div className="elevator__resetCtn">
                                            <button type="button" className="elevator__reset" onClick={() => {
                                                reset();
                                                setMessageFinal(false);
                                               /* if (ga) {
                                                    ReactGA.event({
                                                        category: 'elevator_restart',
                                                        action: 'Click',
                                                        label: 'Renouveler l\'experience'
                                                    });
                                                }*/
                                            }
                                            }>
                                                <i className="icon-reset"></i>
                                                <span>{data.message_reset}</span>
                                            </button>
                                        </div>
                                    }
                                </div>
                                )
                        }
                    </div>
                }
                <div
                    className={`elevator__sticky${messageFinal ? " elevator__sticky--small" : ""}`}>
                    <span>
                        {
                            name || messageFinal ?
                                (messageFinal ? textMessageFinal : data.message_comeback + " " + name)
                                : data.message_new
                        }
                    </span>
                </div>
            </div>
        )
    };

    return (
        <>
            {(data) ? renderElevator() : <div>Elevator Loading</div>}
        </>
    )


};
export default Elevator;

/*
import React, {Component} from 'react';
import "./elevator.scss"
import ElevatorContent from './elevator-content';

class Elevator extends Component {
    state = {data: this.state.data, ready: false, step: 0, messageFinal: null};
    //apiDataURL = '/json/arbre.json';
    onSubmit = this.props.onSubmit;

    /!*

        initLang(lang) {

            // let url = `${site}/wp-json/df-social-wall/v1/view-all-social-posts/`;
            let url = this.apiDataURL + "_lang=" + lang;
            if (url) {
                fetch(url)
                    .then(response => response.json())
                    .then(json => {
                        let data = this.state.data;
                        data[lang] = json;
                        this.setState({data});
                    });
            }
        }*!/

    finishElevator(data) {
        this.setState({messageFinal: this.state.data.message_resultats});
        this.onSubmit(data)
    }

    render() {
        if (!AudioContext) {
            console.log("API AudioContext non compatible avec ce navigateur");
            return null;
        }

        /!*   let lang = this.props.lang ? this.props.lang : "fr";
           if (!this.state.data[lang]) {
               this.initLang(lang);
               return null;
           }*!/


        let name = this.props.name;

        return (
            <div
                className={"elevator" + (name || this.state.messageFinal ? " elevator--closed" : "")}>
                <div className={"elevator__moteur"}>
                    {
                        !name && !this.state.messageFinal ?
                            (
                                !this.state.ready ?
                                    <button className="elevator__ctaStart elevator__texte activated"
                                            onClick={() => this.setState({ready: true})}>{this.state.data.message_start}</button>
                                    :
                                    <ElevatorContent data={this.state.data} onStarted={null}
                                                     onSubmit={data => this.finishElevator(data)}/>
                            )
                            :
                            (
                                <div
                                    className={"elevator__message" + (this.state.messageFinal ? " elevator__message--small" : "")}>{this.state.messageFinal ? this.state.messageFinal : this.state.data.message_comeback + " " + this.props.name}</div>
                            )
                    }
                </div>
                <div
                    className={"elevator__sticky" + (this.props.hover ? " elevator__sticky--visible" : "") + (this.state.messageFinal ? " elevator__sticky--small" : "")}>{this.props.name || this.state.messageFinal ? (this.props.name ? this.state.data.message_comeback + " " + this.props.name : this.state.messageFinal) : this.state.data.message_new}</div>
            </div>
        )
    }
}

export default Elevator;*/
