import React from 'react';
import { useSelector } from 'react-redux';
import Offers from '../Components/offers';

const FHomeOffers = ({jobs_offer}) => {

    const cookiesFilters = useSelector(state => state.global).filters;

    return (
        <Offers {...jobs_offer} path_filtered={jobs_offer.path_filtered}
            cookies_filtered={cookiesFilters && cookiesFilters.article}
            src="article" />
    )
};

export default FHomeOffers;