import React from 'react';
import JobSectorsSlider from '../Components/job-sectors-slider';

const FHomeJobs = (sectors) => {

    return (
        <JobSectorsSlider {...sectors} />
    )
};

export default FHomeJobs;