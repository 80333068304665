import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

// Hooks
import useApi from "../../Hooks/useApi";
import { useSelector, useDispatch } from 'react-redux';
import { updateModalsOpen } from "../../Store/action";
// Components
import Modal from "./modal";

import { useForm } from "react-hook-form";
import Axios from "axios";

const ResetPasswordModal = ({customColors}) => {
    let dispatch = useDispatch();
    const { register, handleSubmit, getValues, errors, watch, formState } = useForm();
    const [isValidForm, setIsValidForm] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(null);
    const history = useHistory();

    const [urlKey, setUrlKey] = useState(null);
    const [urlMail, setUrlMail] = useState(null);
    let site = useSelector(state => state.global).site;
    let page = useSelector(state => state.page).page;

    const useSelectorModals = useSelector(state => state.modals);
    const modalsOpen = useSelectorModals.openModal;

    useEffect(() => {
        // ?resetPassword=true&mail=vincent.trouillet@havasdigitalfactory.com&key=123456
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const resetPassword = params.get('resetPassword');
        const key = params.get('key');
        const mail = params.get('mail');

        if (key) {
            setUrlKey(key);
        }
        if (mail) {
            setUrlMail(mail);
        }
        if (resetPassword && resetPassword === 'true') {
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': false, 'resetPassword': true }));
        }
    }, [page]);


    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('password', data.password);
        formData.append('password_confirm', data.password_confirm);
        formData.append('key', urlKey);
        formData.append('mail', urlMail);
        const fetch = Axios.post(`${site}/wp-json/bpce/v1/user/password/reset/`, formData);
        fetch.then(res => {
            const { status, data } = res;
            setSubmitMessage(data.message);
            if (data.success && status === 200 && !Object.keys(errors).length) {
                setIsValidForm(true);
                history.push('/');
            } else if (!data.success) {
                setIsValidForm(false);
            }
        })
    };

    const watchPassword = watch('password');


    const [dataFetch, isLoaded] = useApi({ name: 'user/popin/password/reset' });
    const renderModal = () => {
        const { title, text_intro, text_explanations, fields, submit, success, passwordValidation } = dataFetch;
        return (
            <Modal modalName="resetPassword" customColors={customColors}>
                {title && <h3 className="title-30">{title}</h3>}

                {
                    !isValidForm && (
                        <>
                            {text_intro && <p>{text_intro}</p>}

                            <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="c-form-input" data-error={errors.password ? 'true' : 'false'}>
                                    <label htmlFor="password" className="hidden">{fields.password.label}</label>
                                    <input
                                        type="password"
                                        placeholder={fields.password.label}
                                        name="password"
                                        id="password"
                                        ref={register({
                                            required: true,
                                            validate: {
                                                size: (value) => {
                                                    if (!/.{9,}/.test(value)) {
                                                        return fields.password.errors.bad;
                                                    }
                                                },
                                                number: (value) => {
                                                    if (!/[0-9]/.test(value)) {
                                                        return fields.password.errors.bad;
                                                    }
                                                },
                                                uppercase: (value) => {
                                                    if (!/(.*[A-Z].*)/.test(value)) {
                                                        return fields.password.errors.bad;
                                                    }
                                                },
                                                special: (value) => {
                                                    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                                                        return fields.password.errors.bad;
                                                    }
                                                }
                                            }
                                        })}
                                    />
                                </div>
                                {errors.password ?.type === "required" &&
                                    <div className="error">{fields.password.errors.empty}</div>}
                                <div className="reset-password-explanations__container">
                                    <p>{text_explanations}</p>
                                    <ul>
                                        <li style={{ color: /.{9,}/.test(watchPassword) && watchPassword ? '#6abf79' : formState.isSubmitted ? '#e76b72' : '#707070' }}>{passwordValidation.size}</li>
                                        <li style={{ color: /[0-9]/.test(watchPassword) && watchPassword ? '#6abf79' : formState.isSubmitted ? '#e76b72' : '#707070' }}>{passwordValidation.number}</li>
                                        <li style={{ color: /(.*[A-Z].*)/.test(watchPassword) && watchPassword ? '#6abf79' : formState.isSubmitted ? '#e76b72' : '#707070' }}>{passwordValidation.uppercase}</li>
                                        <li style={{ color: /[!@#$%^&*(),.?":{}|<>]/.test(watchPassword) && watchPassword ? '#6abf79' : formState.isSubmitted ? '#e76b72' : '#707070' }}>{passwordValidation.special}</li>
                                    </ul>

                                </div>
                                <div className="c-form-input" data-error={errors.password_confirm ? 'true' : 'false'}>
                                    <label htmlFor="password_confirm" className="hidden">{fields.password_confirm.label}</label>
                                    <input
                                        type="password"
                                        placeholder={fields.password_confirm.label}
                                        name="password_confirm"
                                        id="password_confirm"
                                        ref={register({
                                            required: true,
                                            validate: {
                                                matchesPreviousPassword: (value) => {
                                                    const { password } = getValues();
                                                    return password === value;
                                                },
                                            }
                                        })}
                                    />
                                </div>
                                {errors.password_confirm ?.type === "required" &&
                                    <div className="error">{fields.password_confirm.errors.empty}</div>}
                                {errors.password_confirm ?.type === "matchesPreviousPassword" &&
                                    <div className="error">{fields.password_confirm.errors.bad}</div>}
                                {submitMessage && <div className="error">{submitMessage}</div>}

                                <button type="submit" className="c-button">{submit}</button>
                            </form>
                        </>
                    )
                }
                {
                    isValidForm && (
                        <>
                            <p>{success}</p>
                        </>
                    )
                }

            </Modal>
        )
    }

    return (
        <>
            {isLoaded ? renderModal() : 'Loading'}
        </>
    )
};

export default ResetPasswordModal;