import React, {useEffect, useState} from "react";

// Hooks
import {useDispatch, useSelector} from 'react-redux';
import {updateGlobalStorePage, updateGlobalStorePageBis} from '../Store/action';
import useApi from '../Hooks/useApi';

// Components
import {LoaderPage} from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import MainHeader from "../Layout/main/header";
import Testimony from "../Components/testimony";
import AlertPush from "../Components/alert-push";
import Blogposts from "../Components/blog-posts";
import ElevatorPush from "../Components/elevator-push";
import Offers from "../Components/offers";
import SearchOffers from "../Components/search-offers";
import CLink from "../Components/link";

const TemplateJobList = ({_uid}) => {

    const lang = useSelector(state => state.global).lang;
    const userID = useSelector(state => state.global).userId;

    const cookiesFilters = useSelector(state => state.global).filters;

    const [dataFetch, isLoaded] = useApi({name: 'posts', _uid: _uid});

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-job-list'));
        dispatch(updateGlobalStorePageBis(''));
    }, [dispatch]); // isLoaded

    // Save params for query
    const [value, setValue] = useState('');
    const [searchPath, setSearchPath] = useState('');

    const [params, setParams] = useState({
        'lang': lang,
        'keyword': value,
        'tax_sector': '',
        'tax_contract': '',
        'tax_place': '',
        'tax_experience': '',
        'tax_degree': '',
        'tax_brands': '',
        'tax_department':'',
        'tax_job': '',
        'tax_city': '',
        'tax_country': '',
        'tax_channel': '',
        'jobcode': '',
        'tax_community_job':'',
        'external': false,
        'userID': userID !== null ? userID : '',
        "from": 0,
        'size': 9
    });

    // Event when input search is updated
    const handleChange = (e) => {
        setValue(e);
        setParams({
            ...params,
            keyword: e
        });
    };

    const handleFilters = (e, id) => {
        let values = [];
        e && e.map(({value}) => values.push(value));
        setParams({
            ...params,
            [id]: values.join()
        })
    };

    useEffect(() => {
        let index = 0;
        let path = '';
        for (const filter in params) { // loop sur les params
            if (filter !== 'lang' && filter !== 'from' && filter !== 'size' && filter !== 'userID' && params[filter] && params[filter] !== '') {// je ne prends que les paramètres remplis sauf lang et userID
                if (index === 0) {
                    path = `?${filter}=${params[filter]}`;
                } else {
                    path += `&${filter}=${params[filter]}`
                }
                index++;
            }
        }
        setSearchPath(path);
    }, [params]);

    function renderPage() {
        const {metas, content: {top: {title, introduction}, main: {list, testimony}, aside: {blogposts, job_alert, elevator}, bottom: {jobs_offer, discover_jobs, path_filtered}}} = dataFetch;

        return (
            <>
                <Metatags {...metas} />

                <main className="main">

                    <MainHeader title={title} introduction={introduction}/>
                    <div className="main__content" data-bg="grey">
                        <div className="container">
                            <div className="main__contentFlex">
                                <div className="main__content-primary">
                                    <h2 className="title-40">{list.title}</h2>
                                    {list.items.length > 0 &&
                                    <ul className="buttons-list">
                                        {
                                            list.items.map(({title, link}, key) => {
                                                return (
                                                    <li key={key}>
                                                        <CLink {...link} className={`c-button`}></CLink>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    }
                                    {testimony.length > 0 && testimony.map(({image, quote, author, role, link}, key) => {
                                        return (
                                            <Testimony image={image} link={link} quote={quote}
                                                       author={author} role={role} key={key}/>
                                        )
                                    })
                                    }
                                </div>
                                <div className="main__content-aside">
                                    {(job_alert && Object.keys(job_alert).length > 0) &&
                                    <AlertPush {...job_alert}/>
                                    }
                                    {(elevator && Object.keys(elevator).length > 0) &&
                                    <ElevatorPush {...elevator}/>
                                    }
                                    {
                                    (blogposts && Object.keys(blogposts).length > 0 && blogposts.title) &&
                                    <Blogposts blogposts={blogposts} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {(jobs_offer && Object.keys(jobs_offer).length > 0) &&
                    <Offers {...jobs_offer} path_filtered={path_filtered}
                            cookies_filtered={cookiesFilters && cookiesFilters.jobList}
                            src="jobList"/>
                    }
                    {(discover_jobs && Object.keys(discover_jobs).length > 0) &&
                    <SearchOffers {...discover_jobs} handleChange={handleChange} handleFilters={handleFilters}
                                  searchPath={searchPath}/>
                    }
                </main>
            </>
        )
    }

    return isLoaded ? renderPage() : <LoaderPage/>
};

export default TemplateJobList;