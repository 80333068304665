import React from 'react';
import SocialWall from '../Components/social-wall';

const FHomeSocialwall = ({socials}) => {

    return (
        <SocialWall {...socials}/>
    )
};

export default FHomeSocialwall;