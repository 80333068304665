import React from "react";

const SocialList = ({ className, social }) => {
    return (
        <ul className={className}>
            {
                Object.keys(social).map((keyName, key) => {
                    return (
                        <React.Fragment key={key}>
                            {social[keyName].link.url &&
                                <li>
                                    <a href={social[keyName].link.url} target="_blank"
                                        title={social[keyName].link.title} rel="noopener noreferrer"><i
                                            className={`icon-${keyName}`} /></a>
                                </li>
                            }
                        </React.Fragment>
                    )
                })
            }
        </ul>
    )
};

export default SocialList;