// GLOBAL STATE
export function updateGlobalStoreGlobal(datas) {
    return {type: GLOBAL_UPDATE_GLOBAL, value: datas}
}

export function updateGlobalStoreRoutes(datas) {
    return {type: GLOBAL_UPDATE_ROUTES, value: datas}
}

export function updateGlobalStoreElevator(datas) {
    return {type: GLOBAL_UPDATE_ELEVATOR, value: datas}
}

export function updateGlobalStorePage(datas) {
    return {type: GLOBAL_UPDATE_PAGE, value: datas}
}

export function updateGlobalStorePageBis(datas) {
    return {type: GLOBAL_UPDATE_PAGEBIS, value: datas}
}

export function updateGlobalStoreLang(datas) {
    return {type: GLOBAL_UPDATE_LANG, value: datas}
}

export function updateGlobalStoreSite(datas) {
    return {type: GLOBAL_UPDATE_SITE, value: datas}
}

export function updateGlobalisLoaded(bool) {
    return {type: GLOBAL_IS_LOADED, value: bool}
}

export function updateGlobalisRestricted(bool) {
    return {type: GLOBAL_IS_RESTRICTED, value: bool}
}

// TARTEAUCITRON YOUTUBE STATE
export function updateGlobalYoutube(bool) {
    return {type: GLOBAL_IS_YOUTUBE, value: bool}
}

// USER ID
export function updateGlobalUser(datas) {
    return {type: GLOBAL_USER, value: datas}
}

// ELEVATOR PITCH
export function updateElevatorResults(datas) {
    return {type: GLOBAL_ELEVATOR_RESULTS, value: datas}
}

export function updateElevatorShowed(bool) {
    return {type: GLOBAL_ELEVATOR_SHOWED, value: bool}
}

// BREAKPOINTS
export function updateIs767(bool) {
    return {type: IS_767, value: bool}
}

export function updateIs991(bool) {
    return {type: IS_991, value: bool}
}

export function updateIs1024(bool) {
    return {type: IS_1024, value: bool}
}

export function updateIs1199(bool) {
    return {type: IS_1199, value: bool}
}

export function updateModalsOpen(datas) {
    //console.log(datas);
    return {type: MODALS_OPEN, value: datas}
}

export function updateModalsLoginType(datas) {
    return {type: MODALS_LOGINTYPE, value: datas}
}

export function updateModalsRedirectButton(datas) {
    return {type: MODALS_REDIRECTBUTTON, value: datas}
}

export function updateModalsOpeningId(string) {
    return {type: MODALS_OPENINGID, value: string}
}

/*export function updateGA(bool) {
    return {type: GA, value: bool}
}*/

export function updateDataDriven(bool) {
    return {type: DATA_DRIVEN, value: bool}
}

export function updateReferrer(string) {
    return {type: REFERRER, value: string}
}

export function updatePreviousLocation(string) {
    return {type: PREVIOUS_LOCATION, value: string}
}

export function updateFilters(datas) {
    return {type: FILTERS, value: datas}
}

export function updatePathFr(string) {
    return {type: UPDATE_PATH_FR, value: string}
}
export function updatePathEn(string) {
    return {type: UPDATE_PATH_EN, value: string}
}


export const GLOBAL_UPDATE_GLOBAL = 'UPDATE_GLOBAL';
export const GLOBAL_UPDATE_ROUTES = 'UPDATE_ROUTES';
export const GLOBAL_UPDATE_ELEVATOR = 'UPDATE_ELEVATOR';
export const GLOBAL_UPDATE_PAGE = 'UPDATE_PAGE';
export const GLOBAL_UPDATE_PAGEBIS = 'UPDATE_PAGEBIS';
export const GLOBAL_UPDATE_LANG = 'UPDATE_LANG';
export const GLOBAL_UPDATE_SITE = 'UPDATE_SITE';
export const GLOBAL_IS_LOADED = 'IS_LOADED';
export const GLOBAL_IS_RESTRICTED = 'IS_RESTRICTED';
export const GLOBAL_IS_YOUTUBE = 'IS_YOUTUBE';
export const GLOBAL_USER = 'USER';
export const IS_767 = 'IS_767';
export const IS_991 = 'IS_991';
export const IS_1024 = 'IS_1024';
export const IS_1199 = 'IS_1199';
export const MODALS_OPEN = 'MODALS_OPEN';
export const MODALS_LOGINTYPE = 'MODALS_LOGINTYPE';
export const MODALS_REDIRECTBUTTON = 'MODALS_REDIRECTBUTTON';
export const MODALS_OPENINGID = 'MODALS_OPENINGID';
export const GLOBAL_ELEVATOR_RESULTS = 'GLOBAL_ELEVATOR_RESULTS';
export const GLOBAL_ELEVATOR_SHOWED = 'GLOBAL_ELEVATOR_SHOWED';
// export const GA = 'GA';
export const DATA_DRIVEN = 'DATA_DRIVEN';
export const REFERRER = 'REFERRER';
export const PREVIOUS_LOCATION = 'PREVIOUS_LOCATION';
export const FILTERS = 'FILTERS';
export const UPDATE_PATH_FR = 'UPDATE_PATH_FR';
export const UPDATE_PATH_EN = 'UPDATE_PATH_EN';