import React, {useState} from "react";

const Share = ({title, links}) => {

    const [openShare, setOpenShare] = useState(false);

    return (
        <div className="c-share" data-focus={openShare} onFocus={() => {setOpenShare(true)}} onBlur={() => {setOpenShare(false)}}>
            <button type="button" className="c-share-button">
                {title} : <i className="icon-share"></i>
            </button>
            {(links && Object.keys(links).length > 0) &&
            <ul className="c-share-list">
                {
                    Object.keys(links).map((keyName, key) => {
                        return (
                            <li key={key}>
                                <a href={links[keyName]} target="_blank" rel="noopener noreferrer"
                                   title={`${title} - ${keyName}`}><i className={`icon-${keyName}`}></i></a>
                            </li>
                        )
                    })
                }
            </ul>
            }
        </div>
    )
}

export default Share;