import React from "react";
import Quote from "./quote";
import CLink from "./link";

const Ambassador = ({title, link, testimony}) => {
    return (
        <div className="c-ambassador">
            {(testimony.link && Object.keys(testimony.link).length > 0) ?
                <CLink {...testimony.link}>
                    <AmbassadorContent title={title} {...testimony}/>
                </CLink>
                :
                <AmbassadorContent title={title} {...testimony}/>
            }

            {(link && Object.keys(link).length > 0) &&
            <div className="c-ambassador-button">
                <CLink {...link} className={`c-button`}/>
            </div>
            }
        </div>
    )
};

const AmbassadorContent = ({title, is_ambassador, photo, author, role, quote}) => {
    return (
        <>
            {title &&
            <>
                <h3 className="title-30 c-ambassador-title">{title}</h3>
                <img src={require('../Assets/img/logo-myjobglasses.png')} alt="Logo Myjobglasses"/>
            </>
            }
            {is_ambassador &&
            <>
                {(photo && Object.keys(photo).length > 0) &&
                <div className="c-ambassador-img">
                    <div className="c-ambassador-imgCrop">
                        <img src={photo.src} alt={photo.alt}/>
                    </div>
                </div>
                }
            </>
            }

            <Quote text={quote} name={author} role={role}/>
        </>
    )
};

export default Ambassador;