import React from 'react';
import {Link} from "react-router-dom";

// Import Swiper React components
import SwiperCore, {Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Components
import CLink from "./link";

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const JobSectorsSlider = ({title, list, link}) => {

    const ReduceImg = () => {
        const allImg = document.querySelectorAll(".item-img");
        allImg.forEach((img) => {
            if (!img.classList.contains('reduce')) {
                img.classList.add('reduce');
            }
        })
    };

    const IncreaseImg = () => {
        const allImg = document.querySelectorAll(".item-img");
        allImg.forEach((img) => {
            if (img.classList.contains('reduce')) {
                img.classList.remove('reduce');
            }
        })
    };

    return (
        <>
            {(list && list.length > 0) &&
            <div className="c-job-sectors paddingcontrol">
                <div className="container">
                    <h2 className="title-50">{title}</h2>

                    <Swiper className="c-job-sectors-slider"
                            spaceBetween={10}
                            speed={100}
                            navigation
                            autoHeight={true}
                            pagination={{clickable: true}}
                            slidesPerView={1}
                            grabCursor={true}
                            noSwiping={false}
                            scrollbar={{draggable: true}}
                            onSliderMove={() => ReduceImg()}
                            onTouchEnd={() => IncreaseImg()}
                            freeMode={false}
                            resistance={false}
                            freeModeMomentumRatio={1}
                            freeModeMomentumVelocityRatio={0.5}
                            freeModeMomentumBounceRatio={1}
                            breakpoints={{
                                1025: {
                                    spaceBetween: 22,
                                    freeMode: true,
                                    slidesPerView: 'auto',
                                    autoHeight: false
                                }
                            }}
                    >
                        {
                            list.map(({image, title, link}, key) => {
                                return (
                                    <SwiperSlide key={key}>
                                        {link?.internal ?
                                            <Link to={link.url} title={link.title} className="item-img"
                                                  style={{
                                                      backgroundImage: "url(" + image.src + ")"
                                                  }}><span>{link.title}</span></Link>
                                            :
                                            <a href={link.url} title={link.title} className="item-img"
                                               style={{
                                                   backgroundImage: "url(" + image.src + ")"
                                               }}><span>{link.title}</span></a>
                                        }
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>

                    {(link && Object.keys(link).length > 0) &&
                    <div className="c-job-sectors-button">
                        <CLink {...link} className={`c-button`}/>
                    </div>
                    }
                </div>
            </div>
            }
        </>
    )
};

export default JobSectorsSlider;