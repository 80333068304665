import React from 'react';

import Video from "../Components/video";
import Quote from "../Components/quote";

const FVideo = ({index, title, youtube, image, quote}) => {
    return (
        <div className="flexible f-video margincontrol" data-bg={(index % 2 === 0) ? 'grey' : 'white'}>
            <div className="container container--mini">
                <div className="c-testimony f-video-content">
                    <Video youtubeID={youtube} image={image}/>
                    {(!quote.name && title) &&
                    <div className="c-quote c-quote--noicon">
                        <div className="c-quote-author"><span><strong>{title}</strong></span></div>
                    </div>
                    }
                    {quote.name &&
                    <Quote text={quote.text} name={quote.name} role={quote.role}/>
                    }
                </div>
            </div>
        </div>
    )
};

export default FVideo;