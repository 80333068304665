import React from 'react';
import { useSelector } from 'react-redux';
import CLink from '../Components/link';

const FHomeGroup = () => {

    let dataGlobal = useSelector(state => state.global).global;

    // DESTRUCTURING
    const {baseline, companies} = dataGlobal.pre_footer;

    return (
        <>
            {(baseline || companies) &&
            <div className="c-group margincontrol" data-bg="grey">
                <div className="container">
                    {(baseline && Object.keys(baseline).length > 0) &&
                    <p className="c-group-intro"><span className="title-50">{baseline.strong}</span> <span
                        dangerouslySetInnerHTML={{__html: baseline.text}}></span>
                    </p>}
                    {(companies && companies.length > 0) &&
                    <ul className="c-group-logos">
                        {
                            companies.map(({link, logo}, key) => {
                                return (
                                    <li key={key}>
                                        <CLink {...link}>
                                            <img src={logo.image.src}
                                                 alt={logo.image.alt}/>
                                        </CLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    }
                </div>
            </div>
            }
        </>
    )
};

export default FHomeGroup;